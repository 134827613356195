import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SocialUserEntity } from 'shared/entity/social/SocialUserEntity';
import { SocialUserCard } from '../components/SocialUserCard';
import { querySocialFollowers } from '../queries';

export const SocialFollowersPage = () => {

  const {t} = useTranslation();
  const {
    data,
    loading,
  } = useQuery(querySocialFollowers);

  return <>
    {!data?.socialFollowers.length && t('You have no followers')}
    {data?.socialFollowers.map((socialUser: SocialUserEntity) => <SocialUserCard socialUser={socialUser}
      key={socialUser.id}/>)}
  </>;
};
