import {checkAuth} from '../wrappers/AuthWrapper';
import {url} from '../../../url';

export const fetchFile = async (user: string, entity: string, id: string, file: string) => {
  await checkAuth();
  const requestOptions = {
    headers: {
      'authorization': 'bearer ' + localStorage.getItem('token'),
    },
  };
  const requestUrl: string = `${url.apiUrl}/${url.fileUrl}/${user}/${entity}/${id}/${file}`;
  const value = await fetch(requestUrl, requestOptions);
  return value.text();
};
