import {TextField} from '@mui/material';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {url} from '../../../url';
import {restRequest} from '../../../utils';
import {WButton} from '../../common/components/WButton';

export const RecoverPasswordPage = () => {
  const {
    register,
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const params = useParams();
  const {t} = useTranslation();

  const onSubmit = (data: any) => {
    data.token = params.token;
    restRequest(setLoading, data, url.recoverPassword, navigate);
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={t('Password')}
        variant='outlined'
        className={'w-100 margin-1'}
        required
        type={'password'}
        {...register('password')}
      />
      <WButton
        text={t('Recover password')}
        variant={'contained'}
        disabled={loading}
        loading={loading}
        type={'submit'}
        extra={{
          'data-testid': 'recover-password',
        }}
      />
    </form>
  </>;
};
