import React from 'react';
import {Route} from 'react-router-dom';
import {ExpensesIndexPage} from './ExpensesIndexPage';
import {ExpensesCostGroupPage} from './pages/ExpensesCostGroupPage';
import {ExpensesCostGroupsPage} from './pages/ExpensesCostGroupsPage';
import {ExpensesDataGroupPage} from './pages/ExpensesDataGroupPage';
import {ExpensesDataGroupsPage} from './pages/ExpensesDataGroupsPage';
import {ExpensesLoadTransfersPage} from './pages/ExpensesLoadTransfersPage';
import {ExpensesPropertiesPage} from './pages/ExpensesPropertiesPage';
import {ExpensesPropertyPage} from './pages/ExpensesPropertyPage';

const prefix: string = 'expenses';

export const ExpensesRoutes = <>
  <Route path={`${prefix}`}
    element={<ExpensesIndexPage/>}/>

  <Route path={`${prefix}/property`}
    element={<ExpensesPropertyPage/>}/>
  <Route path={`${prefix}/property/:id`}
    element={<ExpensesPropertyPage/>}/>
  <Route path={`${prefix}/properties`}
    element={<ExpensesPropertiesPage/>}/>

  <Route path={`${prefix}/costGroup`}
    element={<ExpensesCostGroupPage/>}/>
  <Route path={`${prefix}/costGroup/:id`}
    element={<ExpensesCostGroupPage/>}/>
  <Route path={`${prefix}/costGroups`}
    element={<ExpensesCostGroupsPage/>}/>

  <Route path={`${prefix}/dataGroup`}
    element={<ExpensesDataGroupPage/>}/>
  <Route path={`${prefix}/dataGroup/:id`}
    element={<ExpensesDataGroupPage/>}/>
  <Route path={`${prefix}/dataGroups`}
    element={<ExpensesDataGroupsPage/>}/>

  <Route path={`${prefix}/loadTransfers`}
    element={<ExpensesLoadTransfersPage/>}/>
</>;
