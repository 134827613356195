import {ApolloError, ServerError, useMutation, useQuery} from '@apollo/client';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {url} from '../../../url';
import {onError} from '../../../utils';
import {fetchFile} from '../../auth/utils/fetchFile';
import {WButton} from '../../common/components/WButton';
import {mutationCreateTextPost, mutationDeleteTextPost, mutationUpdateTextPost} from '../postCrudMutations';
import {queryFollowingTextPosts, queryTextPost, queryTextPosts} from '../queries';

export const TextPostPage = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const params = useParams();
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();
  const [loading, setLoading] = useState<boolean>();
  const [file, setFile] = useState<string | null>();
  const [image, setImage] = useState<string | null>();

  const hasParams = useMemo(() => {
    return !!Object.keys(params).length;
  }, [params]);

  const {
    loading: loadingLabel,
    data,
    error,
  } = useQuery(queryTextPost, {
    variables: {
      id: params.id,
    },
    skip: !hasParams,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // For some reason form won't update value if not reseted.
      reset();
    },
    onError: onError,
  });

  const entity = data?.textPost;

  const [mutateFunctionCreate] = useMutation(mutationCreateTextPost, {
    refetchQueries: [
      // query parameter is needed to avoid new queries warning
      {query: queryTextPosts},
      {query: queryFollowingTextPosts},
    ],
    onError: (error: ApolloError) => {
      const networkError = error.networkError as ServerError;
      toast(networkError.result.errors[0].message);
    },
  });
  const [mutateFunctionUpdate] = useMutation(mutationUpdateTextPost);
  const [mutateFunctionDelete] = useMutation(mutationDeleteTextPost);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (hasParams) {
        await mutateFunctionUpdate({
          variables: {
            input: {
              ...data,
              id: params.id,
            },
            file,
          },
        });
      } else {
        await mutateFunctionCreate({
          variables: {
            input: data,
            file,
          },
          onCompleted: () => {
            setFile(null);
            setImage(null);
            reset({
              // todo: is really annoying that reset removes the data object needed for the mutation
              text: '',
            });
          },
        });
      }
    } catch {
      setLoading(false);
    }
    setLoading(false);
  };

  const remove = () => {
    mutateFunctionDelete({
      variables: {
        id: params.id,
      },
      refetchQueries: [
        {query: queryTextPosts},
        {query: queryFollowingTextPosts},
      ],
    }).then(() => {
      navigate(-1);
    });
  };

  const isLoading = () => {
    return loadingLabel || loading;
  };

  const isDisabled = () => {
    return loadingLabel || loading || !!error;
  };

  const changePicture = () => {
    inputFileRef.current?.click();
  };
  const inputFileRef = useRef<HTMLInputElement>(null);

  const changeHandler = (event: any) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  const userId = useMemo(() => localStorage.getItem('userId'), []);

  useEffect(() => {
    if (!entity) {
      return;
    }
    // fetchPostImage(navigate, data?.textPost.file, setImage, data?.textPost.socialUser.id, data?.textPost.file).then();
    fetchFile(entity.socialUser.id, 'posts', entity.id, entity.file).then(value => {
      setImage(`${url.apiUrl}/${url.file}/${userId}/${entity.socialUser.id}/posts/${entity.id}/${entity.file}?token=${value}`);
    });
  }, [entity]);

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        {...register('text')}
        className={`${isLoading() && 'loading'}`}
        disabled={isDisabled()}
        defaultValue={data?.textPost.text}
      />
      {image &&
        <img alt=''
          src={image}
          className={'w-100'}/>
      }
      <WButton
        text={t('Upload picture')}
        onClick={changePicture}
      />
      <input type='file'
        ref={inputFileRef}
        onChange={changeHandler}
      />
      <WButton
        text={!hasParams ? t('Create') : t('Save')}
        type='submit'
        disabled={isDisabled()}
        loading={isLoading()}
      />
    </form>
    {hasParams && <WButton
      text={t('Delete')}
      onClick={remove}
      disabled={isDisabled()}
      loading={isLoading()}
    />}
  </>;
};
