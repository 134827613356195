import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { querySocialFollowersCounters } from '../queries';

export const SocialFollowersComponent = () => {
  const {t} = useTranslation();
  const {
    data,
    loading,
  } = useQuery(querySocialFollowersCounters);

  return <>
    <div>
      {t('Followers')}: {data?.socialFollowersCounters.followers}
    </div>
    <div>
      {t('Followees')}: {data?.socialFollowersCounters.following}
    </div>
  </>;
};
