import {TextField} from '@mui/material';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {url} from '../../../url';
import {restRequest} from '../../../utils';
import {BackButton} from '../../common/components/BackButton';
import {WButton} from '../../common/components/WButton';

export const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const {t} = useTranslation();

  const onSubmit = (data: any) => {
    restRequest(setLoading, data, url.forgotPassword);
  };

  return <>
    <h1>{t('Forgot password')}</h1>
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={t('Email')}
        variant='outlined'
        className={'w-100 margin-1'}
        required
        {...register('user')}
      />
      <WButton
        text={t('Recover password')}
        disabled={loading}
        loading={loading}
        type={'submit'}
        extra={{
          'data-testid': 'recover-password',
        }}
      />
    </form>
    <BackButton/>
  </>;
};
