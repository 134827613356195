import {useMutation, useQuery} from '@apollo/client';
import {Alert, TextField} from '@mui/material';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {mutationUpdateUser, queryUser, queryUserCellphone} from 'shared/queries/auth/queries';
import {onError} from '../../../utils';
import {WButton} from './WButton';

export const UserPhoneScreen = () => {
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState<boolean>();

  const [mutateFunctionUpdate] = useMutation(mutationUpdateUser);

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await mutateFunctionUpdate({
      variables: {
        input: {
          ...data,
        },
      },
      onCompleted: () => toast.success(''),
      onError,
      refetchQueries: [
        queryUserCellphone
      ]
    });
    setLoading(false);
  };

  const isLoading = () => {
    return loading;
  };

  const isDisabled = () => {
    return loading;
  };

  return <div>
    <div className={'margin-1'}>
      <Alert severity='info'
        variant='filled'>
        Le damos la bienvenida! Antes de continuar necesitamos su numero de celular.
      </Alert>
    </div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={t('Phone')}
        variant='outlined'
        className={'w-100 margin-1'}
        type={'phone'}
        {...register('phone')}
        // defaultValue={user.phone}
        required
      />
      <WButton
        text={t('Save')}
        disabled={isDisabled()}
        isLoading={isLoading()}
        type={'submit'}
      />
    </form>
  </div>;
};
