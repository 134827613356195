import {useQuery} from '@apollo/client';
import {Avatar, IconButton} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserEntity} from 'shared/entity/auth/UserEntity';
import {queryOtherUserImageFile} from 'shared/queries/auth/queries';
import {ConfigContext} from '../../../Config';
import {url} from '../../../url';
import {fetchFile} from '../../auth/utils/fetchFile';

class UserImageProps {
  user?: UserEntity;
  clickable? = false;
}

export const UserImage = (props: UserImageProps) => {
  const navigate = useNavigate();
  const context = useContext(ConfigContext);
  const [image, setImage] = useState<string>();

  const skipQuery = !context.state.userId || !!props.user?.imageFile;
  const id = props.user?.id ?? context.state.userId;

  const {data: qOtherUserImageFile, refetch} = useQuery(queryOtherUserImageFile, {
    skip: skipQuery,
    variables: {
      id,
    },
  });

  const user = qOtherUserImageFile?.otherUser ?? props.user;

  useEffect(() => {
    const userId = context.state.userId;
    if (!user?.imageFile || !userId) {
      setImage(undefined);
      return;
    }
    fetchFile(user.id, 'profile', user.id, user.imageFile).then(value => {
      setImage(`${url.apiUrl}/${url.file}/${userId}/${user.id}/profile/${user.id}/${user.imageFile}?token=${value}`);
    });
  }, [
    user,
    context.state.userId,
  ]);

  useEffect(() => {
    if (!skipQuery) {
      refetch();
    }
  }, [context.state.userId]);

  const click = () => {
    if (location.pathname != '/user') {
      navigate('/user');
    }
  };

  let component = <Avatar src={image}/>;

  if (props.clickable) {
    component = <IconButton
      sx={{p: 0}}
      onClick={click}
    >{component}</IconButton>;
  }

  {/*{image && <div className={'profile-picture-nav'}>*/}
  {/*  {image && <img alt={''}*/}
  {/*    src={image}/>}*/}
  {/*</div>}*/}

  return <>
    {context.state.userId && component}
  </>;
};
