import { useContext } from 'react';
import { ConfigContext } from '../../../Config';
import { MDButton } from './MDButton';
import { TButton } from './TButton';

/*
KButton stands for Which Button
 */
export const WButton = (props: any) => {

  const context = useContext(ConfigContext);

  let component;
  if (context.state.materialDesign) {
    component = <MDButton {...props}></MDButton>;
  } else {
    component = <TButton {...props}></TButton>;
  }

  return component;
};
