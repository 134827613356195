import { gql } from '@apollo/client';

export const mutationCreateDatingSetting = gql`
  mutation ($input: CreateDatingSettingInput!) {
    createDatingSetting(input: $input) {
      id
      range
      minAge
      maxAge
      gender
    }
  }
`;

export const queryDatingSetting = gql`
  query {
    datingSetting {
      id
      range
      minAge
      maxAge
      gender
    }
  }
`;

