import {TextField} from '@mui/material';
import jwt_decode from 'jwt-decode';
import {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ConfigAction, ConfigContext} from '../../../Config';
import {config} from '../../../config';
import {url} from '../../../url';
import {delay} from '../../../utils';
import {WButton} from '../../common/components/WButton';

export const LoginPage = () => {
  const {
    register,
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const context = useContext(ConfigContext);
  const [loading, setLoading] = useState<boolean>();

  const onSubmit = (data: any) => {
    setLoading(true);
    // data.password = hashPasswordFrontend(data.password);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      // credentials: 'include' as RequestCredentials,
    };
    fetch(`${url.apiUrl}/${url.login}`, requestOptions)
      .then(async response => {
        if (response.ok) {
          const json = await response.json();
          localStorage.setItem('token', json.token);
          localStorage.setItem('refreshToken', json.refreshToken);
          const {userId} = jwt_decode(json.token) as { userId: string };
          localStorage.setItem('userId', userId);
          context.dispatch({
            type: ConfigAction.loggedIn,
            value: true,
          });
          await context.dispatch({
            type: ConfigAction.userId,
            value: userId,
          });
          await delay();
          navigate('/', {
            replace: true,
          });
        } else {
          toast.error(response.statusText);
        }
      })
      .catch((reason) => {
        console.error(reason);
        toast.error(reason.message);
      })
      .finally(() => setLoading(false));
  };

  const guest = () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
    };
    fetch(`${url.apiUrl}/${url.guest}`, requestOptions)
      .then(async response => {
        if (response.ok) {
          const json = await response.json();
          localStorage.setItem('token', json.token);
          localStorage.setItem('refreshToken', json.refreshToken);
          const {userId} = jwt_decode(json.token) as { userId: string };
          localStorage.setItem('userId', userId);
          context.dispatch({
            type: ConfigAction.loggedIn,
            value: true,
          });
          await context.dispatch({
            type: ConfigAction.userId,
            value: userId,
          });
          await delay();
          navigate('/', {
            replace: true,
          });
        } else {
          toast.error(response.statusText);
        }
      })
      .catch((reason) => {
        toast.error(reason.message);
      })
      .finally(() => setLoading(false));
  };

  return <>
    {/*<h1>{t('titles.Log in')}</h1>*/}
    {/*<h1>{config.appName}</h1>*/}
    {config.login.google && <WButton
      text={t('Continue with Google')}
      onClick={() => window.location.assign(`${url.apiUrl}/login/federated/google`)}
      classes={'btn-danger'}
    />}
    {config.login.facebook && <WButton
      text={t('Continue with Facebook')}
      onClick={() => window.location.assign(`${url.apiUrl}/login/federated/facebook`)}
      classes={'btn-facebook'}
    />}
    {config.login.guest && <>
      <WButton
        text={t('Guest*')}
        onClick={guest}
      />
      <h5>{t('guestNotice')}</h5>
    </>}
    {config.login.email && <>
      <hr/>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label={t('Email')}
          variant='outlined'
          className={'w-100 margin-1'}
          required
          type={'email'}
          {...register('user')}
        />
        <TextField
          label={t('Password')}
          variant='outlined'
          className={'w-100 margin-1'}
          required
          type={'password'}
          {...register('password')}
        />
        <WButton
          text={t('Forgot password?')}
          link={'/auth/forgotPassword'}
          variant={'text'}
          noMargin={true}
          classes={'w-inherit'}
          extra={{
            'data-testid': 'forgot-password',
          }}
        />
        <WButton
          text={t('Login')}
          disabled={loading}
          loading={loading}
          type={'submit'}
          extra={{
            'data-testid': 'login',
          }}
        />
      </form>
      <WButton
        text={t('Register')}
        link={'/auth/register'}
        extra={{
          'data-testid': 'register',
        }}
      />
    </>}
    {/*<Link to={'/test'}*/}
    {/*  tabIndex={-1}>*/}
    {/*  <button className={'w-100'}>Test styles</button>*/}
    {/*</Link>*/}
  </>;
};
