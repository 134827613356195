import { gql } from '@apollo/client';

export const queryProfile = gql`
  query {
    profile {
      id
      birthdate
      gender
      description
      latitude
      longitude
    }
  }
`;
