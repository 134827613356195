import React from 'react';
import { Link } from 'react-router-dom';

/*
TButton stands for Theme Button
 */
export const TButton = (props: any) => {

  const colorClass = (props.extra?.color === 'error' ? ' btn-danger' : '');
  const loadingClass = (props.loading ? ' loading' : '');
  const classes = 't-button w-100' + loadingClass + colorClass + ' ' + props.classes;

  let component = <button
    className={classes}
    onClick={props.onClick}
    type={props.type || 'button'}
    disabled={props.disabled || props.loading}
    {...props.extra}
  >
    {props.text}
  </button>;

  if (props.label) {
    component = <>{props.label}<div className={'margin-1'}>{component}</div></>;
  }

  if (props.link) {
    component = <Link
      className={'t-a'}
      to={props.link}
      tabIndex={-1}>
      {component}
    </Link>;
  }

  component = <div className={props.noMargin ? '' : 'margin-1'}>{component}</div>;

  return component;
};
