import { gql } from '@apollo/client';

export const queryMessages = gql`
  query ($id: ID!) {
    messages(id: $id) {
      id
      text
      created
      user {
        id
        name
      }
    }
  }
`;

export const mutationCreateMessage = gql`
  mutation ($input: CreateMessageInput!) {
    createMessage(input: $input) {
        id
    }
  }
`;

export const mutationCreateConversation = gql`
  mutation ($input: CreateConversationInput!) {
    createConversation(input: $input) {
      id
    }
  }
`;
