import {gql} from '@apollo/client';

export const mutationExpensesCostGroupCreate = gql`
  mutation ($input: CreateExpensesCostGroupInput!) {
    expensesCostGroupCreate(input: $input) {
      id
    }
  }
`;

export const mutationExpensesCostGroupDelete = gql`
  mutation($id: ID!) {
    expensesCostGroupDelete(id: $id)
  }
`;

export const queryExpensesCostGroupFind = gql`
  query($id: ID!) {
    expensesCostGroupFind(id: $id) {
      id
      name
      costs {
        amount
        property {
          id
          floor
          apartment
        }
      }
    }
  }
`;

export const queryExpensesCostGroupList = gql`
  query {
    expensesCostGroupList {
      id
      name
    }
  }
`;
