import {ConsortiumGenericCard, ConsortiumGenericCardClasses} from './ConsortiumGenericCard';

type Props = {
  entity: any;
  clickable?: boolean;
}

export const ConsortiumTicket = (props: Props) => {
  return <ConsortiumGenericCard {...props} class={ConsortiumGenericCardClasses.TICKET}/>;
};
