import { useMutation } from '@apollo/client';
import { Cancel, FilePresent } from '@mui/icons-material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WButton } from '../../common/components/WButton';
import { mutationConsortiumBudgetCreate } from 'shared/queries/consortium/budgetQueries';
import {
  queryConsortiumTicket,
  queryConsortiumTickets,
  queryConsortiumTicketsUser,
} from 'shared/queries/consortium/queries';

type ConsortiumBudgetAttachProps = {
  ticket: any,
}
export const ConsortiumBudgetAttach = (props: ConsortiumBudgetAttachProps) => {

  const {t} = useTranslation();
  const inputFileRef = useRef<HTMLInputElement>(null);
  // const [file, setFile] = useState<File | null>();
  const [mutation] = useMutation(mutationConsortiumBudgetCreate);
  const changeFile = () => {
    inputFileRef.current?.click();
  };
  const changeHandler = (event: any) => {
    const file = event.target.files[0];
    // setFile(file);
    upload(file).then();
  };

  const upload = async (file) => {
    await mutation({
      variables: {
        input: {
          ticketId: props.ticket.id,
        },
        file,
      },
      refetchQueries: [
        queryConsortiumTicket,
        queryConsortiumTickets,
        queryConsortiumTicketsUser,
      ],
    });
    // setFile(null);
  };

  return <>
    <><WButton
      text={t('modules.consortium.attachBudget')}
      onClick={changeFile}
    />
    <input type='file'
      ref={inputFileRef}
      onChange={changeHandler}
    /></>
    {/*{file && <>*/}
    {/*  <div className={'margin-1'}><FilePresent/> {file.name} <Cancel onClick={() => setFile(null)}/></div>*/}
    {/*  <WButton*/}
    {/*    text={t('modules.consortium.uploadBudget')}*/}
    {/*    onClick={upload}*/}
    {/*  />*/}
    {/*  <WButton*/}
    {/*    text={t('cancel')}*/}
    {/*    onClick={() => setFile(null)}*/}
    {/*  />*/}
    {/*</>}*/}
  </>;
};
