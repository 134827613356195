import { toast } from 'react-hot-toast';
import { NavigateFunction } from 'react-router-dom';
import { apolloClient } from '../../../apollo';
import { checkAuth } from '../wrappers/AuthWrapper';
import {ConfigAction} from '../../../Config';
import { config } from '../../../config';
import { url } from '../../../url';
import { requestMethods } from '../../../utils';

export const logout = async (doLogoutRequest: boolean, navigate?: NavigateFunction, context?): Promise<void> => {
  if (doLogoutRequest) {
    // On user deletion this is not needed because tokens are cascade deleted
    await checkAuth();
    logoutRequest();
  }
  apolloClient.clearStore().then();
  localStorage.removeItem(config.tokenLocalStorageVariable);
  localStorage.removeItem(config.refreshTokenLocalStorageVariable);
  localStorage.removeItem(config.userIdLocalStorageVariable);
  localStorage.removeItem(config.passwordLocalStorageVariable);
  if (context) {
    context.dispatch({
      type: ConfigAction.userId,
      value: undefined,
    });
    context.dispatch({
      type: ConfigAction.loggedIn,
      value: false,
    });
  }
  if (navigate) {
    navigate(config.loginUrl, {
      replace: true,
    });
  }
};

const logoutRequest = (): void => {
  const requestOptions = {
    method: requestMethods.POST,
    headers: {
      authorization: config.bearerPrefix + localStorage.getItem(config.tokenLocalStorageVariable),
    },
    body: JSON.stringify({
      refreshToken: localStorage.getItem(config.refreshTokenLocalStorageVariable),
    }),
  };
  fetch(`${url.apiUrl}/${url.logout}`, requestOptions)
    .then(async response => {
      if (response.ok) {
        try {
          const json = await response.json();
          toast(json.message);
        } catch (e) {
          /* empty */
        }
      } else {
        toast(response.statusText);
      }
    })
    .catch((reason) => {
      toast.error(reason.message);
    });
};
