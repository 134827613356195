import { useQuery } from '@apollo/client';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { SessionComponent } from '../components/SessionComponent';
import { querySessions } from 'shared/queries/auth/queries';

export const SessionsPage = () => {

  const {
    data,
    loading,
  } = useQuery(querySessions, {
    fetchPolicy: 'network-only',
  });

  return <>
    {loading ? <>loading</> : <>
      <TableContainer component={Paper} className={'margin-1'}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>IP</TableCell>
              {/*<TableCell>Location</TableCell>*/}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.sessions.map((session: any) => <SessionComponent session={session}
              key={session.id}/>)}
          </TableBody>
        </Table>
      </TableContainer>
    </>}
  </>;
};
