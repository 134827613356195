import {AvatarGroup} from '@mui/material';
import {useState} from 'react';
import {UserImage} from '../../common/components/UserImage';
import {ConsortiumVotersList} from './ConsortiumVotersList';

export const ConsortiumVoters = (props: any) => {

  const {users} = props;
  const [openVotersList, setOpenVotersList] = useState(false);

  const maxAvatars = 4;

  const handleOpenVotersList = () => {
    setOpenVotersList(true);
  };

  const handleCloseVotersList = () => {
    setOpenVotersList(false);
  };

  return <div className={'d-flex flex-direction-row'}>
    <AvatarGroup total={users?.length}
      onClick={handleOpenVotersList}>
      {users?.slice(0, maxAvatars).map(user =>
        <UserImage key={user.id}
          user={user}/>,
      )}
    </AvatarGroup>
    <ConsortiumVotersList open={openVotersList}
      onClose={handleCloseVotersList}
      voters={users}/>
  </div>;
};
