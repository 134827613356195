import { useMutation } from '@apollo/client';
import { TableCell, TableRow } from '@mui/material';
import { mutationDeleteSession, querySessions } from 'shared/queries/auth/queries';

export const SessionComponent = (props: any) => {

  const session = props.session;

  const [mutateFunctionDelete] = useMutation(mutationDeleteSession, {
    refetchQueries: [
      {
        query: querySessions,
      },
    ],
  });

  const remove = (id: string) => {
    mutateFunctionDelete({
      variables: {
        id,
      },
    });
  };

  return <>
    <TableRow>
      <TableCell>{session.created}</TableCell>
      <TableCell>{session.ip}</TableCell>
      {/*<TableCell>Buenos Aires, Argentina</TableCell>*/}
      <TableCell>
        <button
          onClick={() => remove(session.id)}>Delete
        </button>
      </TableCell>
    </TableRow>
  </>;
};
