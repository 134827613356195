import { useQuery } from '@apollo/client';
import { Menu } from '@mui/icons-material';
import { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NoteEntity } from 'shared/entity/notes/NoteEntity';
import { decrypt } from '../../../encryptor';
import { delay } from '../../../utils';
import { WButton } from '../../common/components/WButton';
import { LabelsSidebar } from '../components/LabelsSidebar';
import { Note } from '../components/Note';
import { NoteLoading } from '../components/NoteLoading';
import { queryNotes } from '../queries';
import { strings } from '../strings';

export const NotesPage = () => {

  const {t} = useTranslation();
  const [notes, setNotes] = useState<NoteEntity[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [enabled, setEnabled] = useState<boolean>(false);
  const sidebarRef = useRef();

  const password = localStorage.getItem('password');

  useQuery(queryNotes, {
    // For some reason this code throws two errors if using cache-first fetchPolicy
    // fetchPolicy: 'cache-and-network',
    onCompleted: async data => {
      const notes = JSON.parse(JSON.stringify(data.notes));
      if (!password) {
        setEnabled(true);
        return;
      }
      try {
        for (const note of notes) {
          note.text = await decrypt(password, note.text);
        }
      } catch (e) {
        toast(strings.BAD_ENCRYPTION);
        setEnabled(true);
        return;
      }
      await delay();
      setLoading(false);
      setNotes(notes);
      setEnabled(true);
    },
  });

  const menu = () => {
    // @ts-ignore
    sidebarRef.current?.menu();
  };

  return <>{enabled && <>
    <h1>{t('Notes')}</h1>
    <div className={''}>
      <LabelsSidebar ref={sidebarRef}/>
    </div>
    <div>
      {loading &&
        <div>{t('keywordNotice')}</div>}
      <WButton
        text={t('Keyword')}
        link={'/keyword'}
      />
      {password && !loading && <>
        <WButton
          text={'Menu'}
          onClick={menu}
        />
        <WButton
          text={t('Labels')}
          link={'/labels'}
        />
        <WButton
          text={t('Create a note')}
          link={'/note'}
        />
        <div className={'flex'}>
          {loading ? <NoteLoading/> : <>
            {notes ? notes?.map((note: NoteEntity) => <Link key={note.id}
              to={'/note/' + note.id}>
              <Note note={note}/>
            </Link>) : null}
          </>}
        </div>
      </>}
    </div>
  </>}</>;
};
