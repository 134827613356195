import {Card, CardContent} from '@mui/material';
import {LogmateLogEntity} from '../../../../../shared/entity/logmate/LogmateLogEntity';
import {formatDate} from '../../common/utils/date';

interface LogmateLogListProps {
  logs: LogmateLogEntity[];
  onClick: (log: LogmateLogEntity) => void,
}

export const LogmateLogList = (props: LogmateLogListProps) => {

  const logs = props.logs;

  return <>
    {logs?.map((log) =>
      <Card key={log.id}
        onClick={() => props.onClick(log)}
        className={'margin-1'}
        style={{
          backgroundColor: 'lightgray',
        }}
      >
        <CardContent>
          {formatDate(log.created)} {log.name}
        </CardContent>
      </Card>,
    )}
  </>;
};
