import {gql} from '@apollo/client';

export const queryConsortiumPolls = gql`
  query {
    consortiumPollList {
      id
      title
      description
      multipleResponses
      anonymous
      created
      user {
        id
        role
        user {
          id
          name
          imageFile
        }
        properties {
            id
            floor
            apartment
            principal
        }
      }
      options {
        id
        title
        description
      }
    }
  }
`;

export const mutationConsortiumPollCreate = gql`
  mutation ($input: CreateConsortiumPollInput!) {
    consortiumPollCreate(input: $input) {
      id
    }
  }
`;

export const queryConsortiumPoll = gql`
  query($id: ID!) {
    consortiumPollFind(id: $id) {
      id
      title
      description
      multipleResponses
      anonymous
      realtime
      options {
        id
        title
        description
      }
      created
      user {
        id
        user {
          name
        }
        properties {
          id
          floor
          apartment
        }
      }
    }
  }
`;

export const mutationConsortiumPollDelete = gql`
  mutation($id: ID!) {
    consortiumPollDelete(id: $id)
  }
`;
