import { gql } from '@apollo/client';

export const queryNotes = gql`
  query notes {
    notes {
      id
      text
    }
  }
`;

export const queryLabels = gql`
  query labels {
    labels {
      id
      name
    }
  }
`;

export const queryLabel = gql`
  query label($id: ID!) {
    label(id: $id) {
      id
      name
      notes {
        id
        text
      }
    }
  }
`;

export const queryNote = gql`
  query note($id: ID!) {
    note(id: $id) {
      id
      text
      label {
        id
      }
    }
  }
`;

export const mutationCreateNote = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      text
    }
  }
`;

export const mutationUpdateNote = gql`
  mutation updateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      id
      text
    }
  }
`;

export const mutationDeleteNote = gql`
  mutation deleteNote($id: ID!) {
    deleteNote(id: $id)
  }
`;

export const mutationCreateLabel = gql`
  mutation createLabel($input: CreateLabelInput!) {
    createLabel(input: $input) {
      id
      name
    }
  }
`;

export const mutationUpdateLabel = gql`
  mutation updateNote($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      id
      name
    }
  }
`;

export const mutationDeleteLabel = gql`
  mutation deleteLabel($id: ID!) {
    deleteLabel(id: $id)
  }
`;
