import {useQuery} from '@apollo/client';
import {Alert} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {queryConsortiumPolls} from 'shared/queries/consortium/queriesPoll';
import {Apps, config} from '../../../config';
import {BackButton} from '../../common/components/BackButton';
import {ErrorScreen} from '../../common/components/ErrorScreen';
import {WButton} from '../../common/components/WButton';
import {ConsortiumPoll} from '../components/ConsortiumPoll';

export const ConsortiumPollsPage = () => {

  const {t} = useTranslation();

  const {loading, data, error} = useQuery(queryConsortiumPolls);

  if (error) {
    return <ErrorScreen/>;
  }

  const polls = data?.consortiumPollList ?? [];

  return <>
    <div className={'margin-1'}>
      <Alert severity='info'
        variant='filled'>
        Por el momento solo el Consejo puede crear votaciones. Pronto va a poder crear sus propias votaciones y sugerir presupuestos!
      </Alert>
    </div>
    {config.app === Apps.DEVELOPER && <WButton
      text={t('modules.consortium.addPoll')}
      link={'/consortium/poll'}
    />}
    <BackButton/>
    {polls.map(poll => <ConsortiumPoll key={poll.id} entity={poll}
      // clickable={poll.user?.id === userId}
    />)}
  </>;
};
