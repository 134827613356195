import {useMutation} from '@apollo/client';
import {Avatar} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {SocialUserEntity} from 'shared/entity/social/SocialUserEntity';
import {mutationCreateConversation} from 'shared/queries/chat/queries';
import {url} from '../../../url';
import {fetchFile} from '../../auth/utils/fetchFile';
import {WButton} from '../../common/components/WButton';

type SocialUserCardProps = {
  socialUser: SocialUserEntity
}

export const ChatUserCard = (props: SocialUserCardProps) => {

  const navigate = useNavigate();
  const {t} = useTranslation();
  const socialUser = props.socialUser;
  const [mutateFunctionCreateConversation] = useMutation(mutationCreateConversation);
  const [image, setImage] = useState<string>();

  const chat = (id: string) => {
    mutateFunctionCreateConversation({
      variables: {
        input: {users: [id]},
      },
      onCompleted: (data) => {
        navigate(`/chat/${data.createConversation.id}`);
      },
    });
  };

  const userId = useMemo(() => localStorage.getItem('userId'), []);
  useEffect(() => {
    // fetchProfileImage(navigate, true, setImage, socialUser.id).then();
    const entity = socialUser.user;
    if (!entity) {
      return;
    }
    fetchFile(entity.id, 'profile', entity.id, 'profile').then(value => {
      setImage(`${url.apiUrl}/${url.file}/${userId}/${entity.id}/profile/${entity.id}/profile?token=${value}`);
    });
  }, []);

  return <div
    className={'d-flex flex-row social-user-card'}
  >
    <div
      className={'d-flex flex-row header'}
      onClick={() => navigate(`/social/photos/${socialUser.id}`)}
    >
      <Avatar src={image}/>
      <div className={'name'}>{socialUser.user.name}</div>
    </div>
    <div style={{
      flexGrow: 1,
    }}></div>
    <WButton
      text={t('Chat')}
      onClick={() => chat(socialUser.id)}
      noMargin={true}
    />
  </div>;
};
