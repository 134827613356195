import React from 'react';
import { Route } from 'react-router-dom';
import { ConsortiumIndexPage } from './ConsortiumIndexPage';
import { ConsortiumPollPage } from './pages/ConsortiumPollPage';
import {ConsortiumPollsPage} from './pages/ConsortiumPollsPage';
import { ConsortiumPropertiesPage } from './pages/ConsortiumPropertiesPage';
import { ConsortiumPropertyPage } from './pages/ConsortiumPropertyPage';
import { ConsortiumTicketPage } from './pages/ConsortiumTicketPage';
import { ConsortiumTicketsPage } from './pages/ConsortiumTicketsPage';
import { ConsortiumTicketsUserPage } from './pages/ConsortiumTicketsUserPage';

const prefix: string = 'consortium';

export const ConsortiumRoutes: JSX.Element = <>
  <Route path={`${prefix}`}
    element={<ConsortiumIndexPage/>}/>

  <Route path={`${prefix}/property`}
    element={<ConsortiumPropertyPage/>}/>
  <Route path={`${prefix}/property/:id`}
    element={<ConsortiumPropertyPage/>}/>
  <Route path={`${prefix}/properties`}
    element={<ConsortiumPropertiesPage/>}/>

  <Route path={`${prefix}/ticket`}
    element={<ConsortiumTicketPage/>}/>
  <Route path={`${prefix}/ticket/:id`}
    element={<ConsortiumTicketPage/>}/>
  <Route path={`${prefix}/tickets`}
    element={<ConsortiumTicketsPage/>}/>
  <Route path={`${prefix}/tickets/user`}
    element={<ConsortiumTicketsUserPage/>}/>

  <Route path={`${prefix}/poll`}
    element={<ConsortiumPollPage/>}/>
  <Route path={`${prefix}/poll/:id`}
    element={<ConsortiumPollPage/>}/>
  <Route path={`${prefix}/polls`}
    element={<ConsortiumPollsPage/>}/>
</>;
