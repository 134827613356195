import {useMutation} from '@apollo/client';
import {Delete, FilePresent} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import {mutationConsortiumBudgetDelete} from 'shared/queries/consortium/budgetQueries';
import {queryConsortiumTicket} from 'shared/queries/consortium/queries';
import {ConsortiumBudgetLink} from './ConsortiumBudgetLink';

export const ConsortiumBudgetList = (props: any) => {
  const {t} = useTranslation();

  const ticket = props.ticket;

  const userId = useMemo(() => localStorage.getItem('userId'), []);

  const [mutationDelete] = useMutation(mutationConsortiumBudgetDelete);

  const deleteBudget = (budget) => {
    const confirm: boolean = window.confirm(t('common.confirm') ?? undefined);
    if (!confirm) {
      return;
    }
    mutationDelete({
      variables: {
        id: budget.id,
      },
      refetchQueries: [queryConsortiumTicket],
    });
  };

  if (!ticket.budgets) return <></>;

  return <>
    {!!ticket.budgets.length && <>
      <div className={'margin-1'}>Presupuestos:</div>
      {ticket.budgets.map(budget => <div key={budget.id}>
        <FilePresent/>
        <ConsortiumBudgetLink budget={budget}
          ticket={ticket}/>
        {budget.user?.id === userId && <IconButton onClick={() => deleteBudget(budget)}>
          <Delete/>
        </IconButton>}
      </div>)}
    </>}
  </>;
};
