import {useMutation} from '@apollo/client';
import {Card, CardActions, CardContent} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  mutationConsortiumTicketMarkAsPriority,
  queryConsortiumTickets,
  queryConsortiumTicketsUser,
} from 'shared/queries/consortium/queries';
import {ConsortiumPollOptions} from './ConsortiumPollOptions';
import {ConsortiumUserCardHeader} from './ConsortiumUserCardHeader';

export enum ConsortiumGenericCardClasses {
  TICKET = 'ticket',
  POLL = 'poll'
}

type ConsortiumEntityProps = {
  entity: any;
  clickable?: boolean;
  class: string;
}

const styles = {
  title: {
    fontWeight: 'bold',
  },
  pointer: {
    cursor: 'pointer',
  },
};

export const ConsortiumGenericCard = (props: ConsortiumEntityProps) => {

  const navigate = useNavigate();

  const entity = props.entity;

  const [mutateFunctionMarkAsPriority] = useMutation(mutationConsortiumTicketMarkAsPriority);
  const [imageProfile, setImageProfile] = useState<string>();

  // const userId = useMemo(() => localStorage.getItem('userId'), []);
  // useEffect(() => {
  //   // fetchProfileImage(navigate, true, setImageProfile, entity.user.id).then();
  //   const entityUser = entity.user;
  //   if (!entityUser) {
  //     return;
  //   }
  //   fetchFile(entityUser.id, 'profile', entityUser.id, 'profile').then(value => {
  //     setImageProfile(`${url.apiUrl}/${url.file}/${userId}/${entityUser.id}/profile/${entityUser.id}/profile?token=${value}`);
  //   });
  // }, []);

  const priority = () => {
    mutateFunctionMarkAsPriority({
      variables: {id: entity.id},
      refetchQueries: [
        queryConsortiumTickets,
        queryConsortiumTicketsUser,
      ],
    });
  };

  return <Card
    className={'margin-1'}
  >
    <ConsortiumUserCardHeader user={entity.user} created={entity.created}/>
    <CardContent onClick={() => props.clickable && navigate(`/consortium/${props.class}/${entity.id}`)}
      style={props.clickable ? styles.pointer : undefined}
    >
      <div>
        <div style={styles.title}>{entity.title}</div>
        <div className={'margin-1'}>{entity.description}</div>
      </div>
    </CardContent>
    <CardActions className={'p-16'}>
      {/*<ConsortiumBudgetList entity={entity}/>*/}
      {/*<WButton*/}
      {/*  text={!entity.priority ? 'Marcar como prioridad' : 'Prioritario'}*/}
      {/*  classes={entity.priority && 'btn-danger'}*/}
      {/*  onClick={priority}*/}
      {/*/>*/}
      {/*<ConsortiumBudgetAttach entity={entity}/>*/}
      {props.class === ConsortiumGenericCardClasses.POLL && <>
        <ConsortiumPollOptions poll={entity}/>
      </>}
    </CardActions>
  </Card>;
};
