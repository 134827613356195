import React from 'react';
import {Route} from 'react-router-dom';
import {NotificationsIndexPage} from './pages/NotificationsIndexPage';

const prefix: string = 'notifications';

export const NotificationsRoutes = <>
  <Route path={`${prefix}`}
    element={<NotificationsIndexPage/>}/>
</>;
