import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LabelEntity } from 'shared/entity/notes/LabelEntity';
import { decrypt, encrypt } from '../../../encryptor';
import { delay, onError } from '../../../utils';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';
import {
  mutationCreateNote,
  mutationDeleteNote,
  mutationUpdateNote,
  queryLabels,
  queryNote,
  queryNotes,
} from '../queries';

export const NotePage = () => {

  const params = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [text, setText] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [decryptError, setDecryptError] = useState<boolean>();

  const hasParams = useMemo(() => {
    return !!Object.keys(params).length;
  }, [params]);

  const {
    data: dataNote,
    error,
  } = useQuery(queryNote, {
    variables: {
      id: params.id,
    },
    skip: !hasParams,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!password) {
        return;
      }
      decrypt(password, data.note.text).then(async (value: string) => {
        await delay();
        setText(value);
        reset(); // needed because else useForm won't update text value
        setValue('labelId', data.note.label?.id); // todo: is there a better way?
      }).catch(() => {
        setDecryptError(true);
        toast('Failed to decrypt');
      });
    },
    onError: onError,
  });

  const [mutateFunctionCreate] = useMutation(mutationCreateNote, {
    refetchQueries: [
      {
        query: queryNotes,
      },
    ],
    awaitRefetchQueries: true,
  });
  const [mutateFunctionUpdate] = useMutation(mutationUpdateNote, {
    awaitRefetchQueries: true,
  });
  const [mutateFunctionDelete] = useMutation(mutationDeleteNote, {
    refetchQueries: [
      {
        query: queryNotes,
      },
    ],
    awaitRefetchQueries: true,
  });

  const password = useMemo(() => {
    return localStorage.getItem('password')
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    if (!password) {
      setLoading(false);
      return;
    }
    if (!data.text) {
      setLoading(false);
      return; // todo: not quite sure why this happens
    }
    if (!data.labelId) {
      data.labelId = '';
    }
    data.text = await encrypt(password, data.text);
    if (hasParams) {
      await mutateFunctionUpdate({
        variables: {
          input: {
            ...data,
            id: params.id,
          },
        },
      });
      navigate(-1);
    } else {
      await mutateFunctionCreate({
        variables: {
          input: data,
        },
      });
      navigate(-1);
    }
    setLoading(false);
  };

  const remove = () => {
    setLoading(true);
    mutateFunctionDelete({
      variables: {
        id: params.id,
      },
    }).then(() => {
      navigate(-1);
    }).catch(() => {
      setLoading(false);
    });
  };

  const {
    loading: loadingLabels,
    data: dataLabels,
  } = useQuery(queryLabels);

  const isLoading = () => {
    return ((text === undefined && hasParams) || loadingLabels || loading) && !error && !decryptError;
  };

  const isDisabled = () => {
    return isLoading() || !!error || decryptError || !password;
  };

  return <>
    {/*{isLoading() && <div className={'fullcreen-loading'}></div>}*/}
    <form onSubmit={handleSubmit(onSubmit)}>
      <textarea {...register('text')} className={`noteInput ${isLoading() && 'loading'}`}
        defaultValue={text}
        disabled={isDisabled()}
      />
      <div>
        {t('Label')}
        <select {...register('labelId')} defaultValue={dataNote?.note.label?.id}
          disabled={isDisabled()}
          className={`${isLoading() && 'loading'}`}>
          <option></option>
          {dataLabels ? dataLabels.labels.map((label: LabelEntity) =>
            <option key={label.id}
              value={label.id}
            >
              {label.name}
            </option>,
          ) : null}
        </select>
      </div>
      <WButton
        text={!hasParams ? t('Create') : t('Save')}
        type='submit'
        disabled={isDisabled()}
        loading={isLoading()}
      />
    </form>
    {hasParams &&
      <WButton
        text={t('Delete')}
        onClick={remove}
        disabled={isDisabled()}
        loading={isLoading()}
      />
    }
    <BackButton/>
  </>;
};
