import {useMutation, useQuery} from '@apollo/client';
import {CircularProgress, TextField} from '@mui/material';
import React, {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {
  mutationConsortiumTicketCreate,
  mutationConsortiumTicketDelete, mutationConsortiumTicketMarkAsPriority,
  queryConsortiumTicket, queryConsortiumTickets, queryConsortiumTicketsUser,
} from 'shared/queries/consortium/queries';
import {onError} from '../../../utils';
import {BackButton} from '../../common/components/BackButton';
import { CenterLoading } from '../../common/components/CenterLoading';
import {WButton} from '../../common/components/WButton';
import {ConsortiumBudgetAttach} from '../components/ConsortiumBudgetAttach';
import {ConsortiumBudgetList} from '../components/ConsortiumBudgetList';
import {ConsortiumTicket} from '../components/ConsortiumTicket';

export const ConsortiumTicketPage = () => {

  const {t} = useTranslation();
  const {register, handleSubmit} = useForm();
  const params = useParams();
  const navigate = useNavigate();

  const userId = useMemo(() => localStorage.getItem('userId'), []);

  const {loading, data, error} = useQuery(queryConsortiumTicket, {
    skip: !params.id,
    variables: {id: params.id},
  });
  const [mutateFunction] = useMutation(mutationConsortiumTicketCreate);
  const [mutateFunctionDelete] = useMutation(mutationConsortiumTicketDelete);
  const [mutateFunctionMarkAsPriority] = useMutation(mutationConsortiumTicketMarkAsPriority);

  if (loading && !data) {
    return <CenterLoading/>;
  }
  if (error) {
    return <>Error</>;
  }

  let ticket;
  if (params.id) {
    ticket = data.consortiumTicketFind;
  } else {
    ticket = {};
  }

  const onSubmit = async (data: any) => {
    await mutateFunction({
      variables: {
        input: {
          ...{id: params.id},
          ...data,
        },
      },
      onCompleted: () => {
        navigate(-1);
      },
      onError: onError,
    });
  };

  const remove = () => {
    const confirm: boolean = window.confirm(t('common.confirm') ?? undefined);
    if (!confirm) {
      return;
    }
    mutateFunctionDelete({
      variables: {id: params.id},
      onCompleted: () => {
        toast.success('Eliminado');
        navigate(-1);
      },
    });
  };

  const belongs = () => {
    return ticket.user.id === userId;
  };

  const priority = () => {
    mutateFunctionMarkAsPriority({
      variables: {id: ticket.id},
      refetchQueries: [
        queryConsortiumTicket,
        queryConsortiumTickets,
        queryConsortiumTicketsUser,
      ],
    });
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      {params.id && !belongs() ? <ConsortiumTicket entity={ticket}/>
        : <>
          <TextField
            label={t('common.title')}
            variant='outlined'
            className={'w-100 margin-1'}
            {...register('title')}
            defaultValue={ticket.title}
            required
          />
          <TextField
            label={t('common.description')}
            variant='outlined'
            className={'w-100 margin-1'}
            {...register('description')}
            defaultValue={ticket.description}
            multiline
            rows={10}
            required
          /></>}
      {(!params.id || belongs()) && <WButton
        text={t('Save')}
        // disabled={isDisabled()}
        // isLoading={isLoading()}
        type={'submit'}
      />}
      {params.id && <hr/>}
      <ConsortiumBudgetList ticket={ticket}/>
      {params.id && <ConsortiumBudgetAttach ticket={ticket}/>}
      {params.id && <hr/>}
      {params.id && <WButton
        text={!ticket.priority ? 'Marcar como prioridad' : 'Prioritario'}
        classes={ticket.priority && 'btn-danger'}
        onClick={priority}
      />}
      {params.id && belongs() && <WButton
        text={t('Delete')}
        // disabled={isDisabled()}
        // isLoading={isLoading()}
        onClick={remove}
      />}
      <BackButton/>
    </form>
  </>;
};
