import {Warning} from '@mui/icons-material';

export const UserNotEnabledScreen = () => {
  return <div className={'center'}>
    <div className={'error-text'}>
      <div><Warning className={'warning-icon'}/></div>
      <div>Usuario no activado.</div>
      <div>Aguarde a que un administrador active su usuario.</div>
    </div>
  </div>;
};
