import { toast } from 'react-hot-toast';

const options = {
  enableHighAccuracy: true,
  // timeout: 5000,
  maximumAge: 0,
};

function defaultSuccess(pos: { coords: any; }) {
  const crd = pos.coords;
  toast(`Your current position is:\nLatitude : ${crd.latitude}\nLongitude: ${crd.longitude}\nMore or less ${crd.accuracy} meters.`);
}

function error(err: { code: any; message: any; }) {
  toast(`ERROR(${err.code}): ${err.message}`);
}

export const getGeolocation = (success) => {
  navigator.geolocation.getCurrentPosition(success, error, options);
};
