import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { codes } from '../../../codes';
import { mutationValidateEmail } from 'shared/queries/auth/queries';
import { useTranslation } from 'react-i18next';

export const ValidateEmailPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const params = useParams();
  const [message, setMessage] = useState<string | null>();
  const {t} = useTranslation();

  const [mutateFunctionValidateEmail] = useMutation(mutationValidateEmail);

  const validateEmail = async () => {
    setLoading(true);
    try {
      const validated = await mutateFunctionValidateEmail({
        variables: {
          input: {
            token: params.token,
          },
        },
      });
      if (validated.data.validateEmail.code === codes.VALIDATE_SUCCESSFUL) {
        setMessage(t('modules.auth.emailVerified'));
      } else if (validated.data.validateEmail.code === codes.ALREADY_VALIDATED) {
        setMessage(t('modules.auth.emailAlreadyVerified'));
      } else {
        setMessage(t('modules.auth.couldNotVerify'));
      }
    } catch {
      setMessage(t('errors.connectionError'));
    }
    setLoading(false);
  };

  useEffect(() => {
    validateEmail().then();
  }, []);

  return <>
    <div className={'margin-1'}>
      {message}
    </div>
  </>;
};
