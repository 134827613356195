import {useQuery} from '@apollo/client';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ExpensesCostGroupEntity} from 'shared/entity/expenses/ExpensesCostGroupEntity';
import {queryExpensesCostGroupList} from 'shared/queries/expenses/expensesCostGroupCrudQueries';
import {BackButton} from '../../common/components/BackButton';
import {CenterLoading} from '../../common/components/CenterLoading';
import {ErrorScreen} from '../../common/components/ErrorScreen';
import {WButton} from '../../common/components/WButton';

export const ExpensesCostGroupsPage = () => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const {loading, data, error} = useQuery(queryExpensesCostGroupList);

  if (loading) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  const properties: ExpensesCostGroupEntity[] = data.expensesCostGroupList;

  return <>
    <WButton
      text={t('common.new')}
      link={'/expenses/costGroup'}
    />
    <BackButton/>
    <TableContainer component={Paper} className={'margin-1'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>{t('common.name')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map((e) => (
            <TableRow key={e.id} onClick={() => navigate(`/expenses/costGroup/${e.id}`)}>
              <TableCell>{e.id}</TableCell>
              <TableCell>{e.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>;
};
