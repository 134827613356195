import {useMutation, useQuery} from '@apollo/client';
import {TextField} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {mutationUpdateUser, queryOtherUserImageFile, queryUser, queryUserImageFile} from 'shared/queries/auth/queries';
import {ConfigAction, ConfigContext} from '../../../Config';
import {config} from '../../../config';
import {onError} from '../../../utils';
import {BackButton} from '../../common/components/BackButton';
import {WButton} from '../../common/components/WButton';
import {ChangeUserImage} from '../components/ChangeUserImage';
import {DeleteUserButton} from '../components/DeleteUserButton';
import {ValidateEmailButton} from '../components/ValidateEmailButton';
import {logout} from '../utils/logout';

export const UserPage = () => {
  const navigate: NavigateFunction = useNavigate();
  const {t} = useTranslation();
  const context = useContext(ConfigContext);

  const {
    register,
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState<boolean>();
  const [file, setFile] = useState<File>();

  const {
    loading: loadingUser,
    data: dataUser,
    error,
  } = useQuery(queryUser, {
    onError: onError,
  });

  const user = dataUser?.user;

  const [mutateFunctionUpdate] = useMutation(mutationUpdateUser);

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await mutateFunctionUpdate({
      variables: {
        input: {
          ...data,
        },
        file,
      },
      onCompleted: () => toast.success(''),
      onError,
      refetchQueries: [
        queryOtherUserImageFile,
      ],
    });
    context.dispatch({
      type: ConfigAction.imageFile,
      value: result.data.updateUser.imageFile,
    });
    setLoading(false);
  };

  const isLoading = () => {
    return loadingUser || loading;
  };

  const isDisabled = () => {
    return loadingUser || loading || !!error;
  };

  return <>{user && <>
    <form onSubmit={handleSubmit(onSubmit)}>
      {config.modules.profile.picture && <ChangeUserImage user={user}
        setFile={setFile}/>}
      <TextField
        label={t('Name')}
        variant='outlined'
        className={'w-100 margin-1'}
        {...register('name')}
        defaultValue={user.name}
      />
      {config.modules.profile.email && <TextField
        label={t('Email')}
        variant='outlined'
        className={'w-100 margin-1'}
        type={'email'}
        {...register('email')}
        defaultValue={user.email}
      />}
      {config.modules.profile.phone && <TextField
        label={t('Phone')}
        variant='outlined'
        className={'w-100 margin-1'}
        type={'phone'}
        {...register('phone')}
        defaultValue={user.phone}
      />}
      <WButton
        text={t('Save')}
        disabled={isDisabled()}
        isLoading={isLoading()}
        type={'submit'}
      />
    </form>
    <hr/>
    {config.login.email && <WButton
      text={t('Change password')}
      link={'/auth/changePassword'}
    />}
    {config.modules.profile.validate && !user.validated && <ValidateEmailButton/>}
    {config.modules.profile.sessions && <WButton
      text={t('Sessions')}
      link={'/sessions'}
    />}
    {config.modules.profile.delete && <DeleteUserButton/>}
    <WButton
      text={t('Logout')}
      onClick={() => logout(true, navigate, context)}
      extra={{
        color: 'error',
        'data-testid': 'logout',
      }}
    />
    <BackButton/>
  </>}
  </>;
};
