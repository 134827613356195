import {gql} from '@apollo/client';

export const mutationExpensesPropertyCreate = gql`
  mutation ($input: CreateExpensesPropertyInput!) {
    expensesPropertyCreate(input: $input) {
      id
    }
  }
`;

export const mutationExpensesPropertyDelete = gql`
  mutation($id: ID!) {
    expensesPropertyDelete(id: $id)
  }
`;

export const queryExpensesPropertyFind = gql`
  query($id: ID!) {
    expensesPropertyFind(id: $id) {
      id
      floor
      apartment
      dataGroup {
        id
      }
    }
  }
`;

export const queryExpensesPropertyList = gql`
  query {
    expensesPropertyList {
      id
      floor
      apartment
    }
  }
`;
