import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {IndexRedirector} from './IndexRedirector';
import {ChangePasswordPage} from './modules/auth/pages/ChangePasswordPage';
import {SessionsPage} from './modules/auth/pages/SessionsPage';
import {UserPage} from './modules/auth/pages/UserPage';
import {ValidateEmailPage} from './modules/auth/pages/ValidateEmailPage';
import {AuthRoutes} from './modules/auth/routes';
import {AuthWrapper} from './modules/auth/wrappers/AuthWrapper';
import {SetCellphoneWrapper} from './modules/auth/wrappers/SetCellphoneWrapper';
import {UserEnabledWrapper} from './modules/auth/wrappers/UserEnabledWrapper';
import {ChatRoutes} from './modules/chat/routes';
import {ConsortiumRoutes} from './modules/consortium/routes';
import {DatingRoutes} from './modules/dating/routes';
import {ExpensesRoutes} from './modules/expenses/routes';
import {LogmateRoutes} from './modules/logmate/logmateRoutes';
import {TestPage} from './modules/notes/pages/TestPage';
import {NotesRoutes} from './modules/notes/routes';
import {NotificationsRoutes} from './modules/notifications/routes';
import {SocialRoutes} from './modules/social/routes';
import {RequestsTestPage} from './modules/test/pages/RequestsTestPage';

export const AppRoutes =
  <Routes>
    <Route path='*'
      element={<Navigate to='/'
        replace/>}/>
    {AuthRoutes}
    <Route element={<AuthWrapper/>}>
      <Route element={<UserEnabledWrapper/>}>
        <Route element={<SetCellphoneWrapper/>}>
          <Route path='/'
            element={<IndexRedirector/>}/>
          <Route path='auth/validateEmail/:token'
            element={<ValidateEmailPage/>}/>
          <Route path='user'
            element={<UserPage/>}/>
          <Route path='sessions'
            element={<SessionsPage/>}/>
          <Route path='auth/changePassword'
            element={<ChangePasswordPage/>}/>
          {NotesRoutes}
          {SocialRoutes}
          {DatingRoutes}
          {ChatRoutes}
          {NotificationsRoutes}
          {ConsortiumRoutes}
          {ExpensesRoutes}
          {LogmateRoutes}
        </Route>
      </Route>
    </Route>
    <Route path='test'
      element={<TestPage/>}/>
    <Route path='requests-test'
      element={<RequestsTestPage/>}/>
  </Routes>;
