import React from 'react';
import { Route } from 'react-router-dom';
import { DatingIndexPage } from './DatingIndexPage';
import { DatingCandidatesPage } from './pages/DatingCandidatesPages';
import { DatingSettingPage } from './pages/DatingSettingPage';

const prefix: string = 'dating';

export const DatingRoutes: JSX.Element = <>
  <Route path={`${prefix}`}
    element={<DatingIndexPage/>}/>
  <Route path={`${prefix}/settings`}
    element={<DatingSettingPage/>}/>
  <Route path={`${prefix}/candidates`}
    element={<DatingCandidatesPage/>}/>
</>;
