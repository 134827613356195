import { useQuery } from '@apollo/client';
import { queryDatingCandidates } from 'shared/queries/dating/queries';
import { CandidateCard } from '../components/CandidateCard';

export const DatingCandidatesPage = () => {

  const {data} = useQuery(queryDatingCandidates);

  return <>{data?.candidates.map((candidate) => <CandidateCard key={candidate.id} user={candidate}/>)}</>;
};
