import { toast } from 'react-hot-toast';

export const RequestsTestPage = () => {
  const route = (url: string) => {
    fetch(url)
      .then(async response => {
        if (response.ok) {
          const json = await response.json();
          toast.success('Response ok: ' + json.message);
        } else {
          toast.error('Response not ok: ' + response.statusText);
        }
      })
      .catch((reason) => {
        toast.error('Catch: ' + reason.message);
      });
  };

  return <>
    <button className={'w-100'}
      onClick={() => route(`http://localhost:3001/api/json`)}>Ok route
    </button>
    <button className={'w-100'}
      onClick={() => route(`http://localhost:3001/api/unexistent`)}>Error route
    </button>
    <button className={'w-100'}
      onClick={() => route(`http://localhost:5000`)}>Down server
    </button>
    <button className={'w-100'}
      onClick={() => route(`http://localhost:3001/api/error`)}>Error server
    </button>
  </>;
};
