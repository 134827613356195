import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';

export const ChatIndexPage = () => {

  const {t} = useTranslation();

  return <>
    <WButton
      text={t('Users')}
      link={'users'}
    />
    <BackButton/>
  </>;
};
