import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';
import { SocialFollowersComponent } from '../components/SocialFollowersComponent';

export const SocialIndexPage = () => {

  const {t} = useTranslation();

  return <>
    <SocialFollowersComponent/>
    <WButton
      text={t('Posts')}
      link={'text-posts/following'}
    />
    <WButton
      text={t('My posts')}
      link={'text-posts'}
    />
    <WButton
      text={t('Search users')}
      link={'search-users'}
    />
    <WButton
      text={t('Followees')}
      link={'following'}
    />
    <WButton
      text={t('Followers')}
      link={'followers'}
    />
    <WButton
      text={t('Photos')}
      link={'photos'}
    />
    <WButton
      text={t('Profile')}
      link={'profile'}
    />
    <BackButton/>
  </>;
};
