import {useMutation, useQuery} from '@apollo/client';
import {TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FieldValues, FormProvider, useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {ExpensesDataGroupEntity} from 'shared/entity/expenses/ExpensesDataGroupEntity';
import {ExpensesPropertyEntity} from 'shared/entity/expenses/ExpensesPropertyEntity';
import {
  mutationExpensesDataGroupCreate,
  mutationExpensesDataGroupDelete,
  queryExpensesDataGroupFind,
} from 'shared/queries/expenses/expensesDataGroupCrudQueries';
import {queryExpensesPropertyList} from 'shared/queries/expenses/expensesPropertyCrudQueries';
import {onError} from '../../../utils';
import {BackButton} from '../../common/components/BackButton';
import {CenterLoading} from '../../common/components/CenterLoading';
import {ErrorScreen} from '../../common/components/ErrorScreen';
import {WButton} from '../../common/components/WButton';
import {ExpensesDataComponent} from '../components/ExpensesDataComponent';
import {v4 as uuid} from 'uuid';

export const ExpensesDataGroupPage = () => {

  const navigate = useNavigate();
  const params = useParams();
  const methods = useForm();
  const {t} = useTranslation();

  const {loading, data: data, error} = useQuery(queryExpensesDataGroupFind, {
    skip: !params.id,
    variables: {id: params.id},
  });
  const {
    loading: loadingProperties,
    data: dataProperties,
    error: errorProperties,
  } = useQuery(queryExpensesPropertyList);
  const [mutateFunction, {loading: loadingCreate}] = useMutation(mutationExpensesDataGroupCreate);
  const [mutateFunctionDelete, {loading: loadingDelete}] = useMutation(mutationExpensesDataGroupDelete);

  const costGroup: ExpensesDataGroupEntity = data?.expensesDataGroupFind;
  const properties: ExpensesPropertyEntity[] = dataProperties?.expensesPropertyList;

  const [dataList, setDataList] = useState<any>([]);

  useEffect(() => {
    if (costGroup?.data) {
      const newData = JSON.parse(JSON.stringify(costGroup.data));
      setDataList(newData);
    }
  }, [costGroup?.data]);

  const isLoading = () => {
    return loadingCreate || loadingDelete;
  };

  if (loading) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  const onSubmit = async (data: FieldValues) => {
    const curatedDataList = dataList.map(({id, __typename, ...data}) => data);
    data.data = curatedDataList;
    await mutateFunction({
      variables: {
        input: {
          ...{id: params.id},
          ...data,
        },
      },
      onCompleted: () => {
        navigate(-1);
      },
      onError: onError,
    });
  };

  const remove = () => {
    mutateFunctionDelete({
      variables: {id: params.id},
      onCompleted: () => {
        toast.success('Eliminado');
        navigate(-1);
      },
    });
  };

  const add = () => {
    setDataList([
      ...dataList,
      {id: uuid()},
    ]);
  };

  const onDeleteData = (id: string) => {
    const newData = dataList.filter(e => e.id !== id);
    setDataList([
      ...newData,
    ]);
  };

  return <>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField
          className={'margin-1 w-100'}
          label={t('common.name')}
          {...methods.register('name')}
          defaultValue={costGroup?.name}
        />
        <hr/>
        {
          dataList?.map(e => <ExpensesDataComponent
            key={e.id}
            data={e}
            onDelete={onDeleteData}
          />)
        }
        <WButton
          text={t('Agregar dato')}
          onClick={add}
        />
        <WButton
          text={t('Save')}
          loading={isLoading()}
          type={'submit'}
        />
        {params.id && <WButton
          text={t('Delete')}
          loading={isLoading()}
          onClick={remove}
        />}
        <BackButton/>
      </form>
    </FormProvider>
  </>;
};
