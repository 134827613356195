import {useQuery} from '@apollo/client';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {queryConsortiumProperties} from 'shared/queries/consortium/queries';
import {CenterLoading} from '../common/components/CenterLoading';
import {ErrorScreen} from '../common/components/ErrorScreen';
import {WButton} from '../common/components/WButton';

export const ExpensesIndexPage = () => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const {loading: loadingProperties, data, error} = useQuery(queryConsortiumProperties);

  const properties = data?.consortiumPropertyList;

  const loading = loadingProperties || !properties.length;

  useEffect(() => {
    if (properties && !properties.length) {
      navigate('/consortium/properties');
    }
  }, [properties]);

  if (loading) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  return <>
    <WButton
      text={t('modules.consortium.properties')}
      link={'/expenses/properties'}
    />
    <WButton
      text={t('modules.expenses.costGroups')}
      link={'/expenses/costGroups'}
    />
    <WButton
      text={t('modules.expenses.dataGroups')}
      link={'/expenses/dataGroups'}
    />
    <WButton
      text={t('modules.expenses.loadTransfers')}
      link={'/expenses/loadTransfers'}
    />
    {/*<BackButton/>*/}
  </>;
};
