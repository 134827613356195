import {useMutation} from '@apollo/client';
import {useState} from 'react';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {mutationSendValidationEmail} from 'shared/queries/auth/queries';
import {onError} from '../../../utils';
import {WButton} from '../../common/components/WButton';

export const ValidateEmailButton = () => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const [mutateFunctionSendValidationEmail] = useMutation(mutationSendValidationEmail);

  const validateEmail = async () => {
    setLoading(true);
    await mutateFunctionSendValidationEmail({
      onError,
      onCompleted: () => {
        toast(t('modules.auth.validationEmailSent'));
      },
    });
    setLoading(false);
  };

  return <WButton
    text={t('Validate email')}
    onClick={validateEmail}
    loading={loading}
  />;
};
