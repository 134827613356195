import { fromByteArray, toByteArray } from 'base64-js';

const getDigest = async (string: string) => {
  const enc = new TextEncoder();
  const digest = await crypto.subtle.digest('SHA-256', enc.encode(string));
  return new Uint8Array(digest);
};

const getKey = (value: Uint8Array) => {
  return crypto.subtle.importKey('raw', value, 'AES-CBC', true, [
    'encrypt',
    'decrypt',
  ]);
};

const getIv = () => {
  const enc = new TextEncoder();
  return enc.encode('1234567890123456');
};

export const encrypt = async (password: string, string: string) => {
  console.log(string.length);
  const enc = new TextEncoder();
  const passwordDigest = await getDigest(password);
  const key = await getKey(passwordDigest);
  const iv = getIv();
  const encodedString = enc.encode(string);
  const cipher = await crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    key,
    encodedString,
  );
  console.log(new Uint8Array(cipher).length);
  return fromByteArray(new Uint8Array(cipher));
};

export const decrypt = async (password: string, string: string): Promise<string> => {
  const encodedString = toByteArray(string);
  const passwordDigest = await getDigest(password);
  const key = await getKey(passwordDigest);
  const iv = getIv();
  const array = await crypto.subtle.decrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    key,
    encodedString,
  );
  return new TextDecoder().decode(array);
};
