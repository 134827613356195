import ErrorIcon from '@mui/icons-material/Error';

export const ErrorScreen = () => {
  return <div className={'center'}>
    <div className={'error-text'}>
      <div><ErrorIcon className={'error-icon'}/></div>
      <div>Ha ocurrido un error.</div>
      <div>Por favor, refresque la página.</div>
    </div>
  </div>;
};
