import {gql} from '@apollo/client';

export const mutationConsortiumPollVoteCreate = gql`
  mutation ($input: ConsortiumPollVoteCreateInput!) {
    consortiumPollVoteCreate(input: $input) {
      id
    }
  }
`;

export const mutationConsortiumPollVoteDelete = gql`
  mutation($id: ID!) {
    consortiumPollVoteDelete(id: $id)
  }
`;

export const queryConsortiumPollVotes = gql`
  query($input: ConsortiumPollVotesInput!) {
    consortiumPollVotes(input: $input) {
      optionId
      votes
      votedByUser
      users {
        id
        role
        user {
          id
          name
        }
        properties {
          id
          floor
          apartment
          principal
        }
      }
    }
  }
`;
