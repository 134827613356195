import { gql } from '@apollo/client';

export const queryConsortiumProperty = gql`
  query($input: FindConsortiumPropertyInput!) {
    consortiumPropertyFind(input: $input) {
      id
      floor
      apartment
      type
      principal
    }
  }
`;

export const queryConsortiumTicket = gql`
  query($id: ID!) {
    consortiumTicketFind(id: $id) {
      id
      title
      description
      priority
      created
      user {
        id
        user {
          name
        }
        properties {
            id
            floor
            apartment
        }
      }
      budgets {
        id
        name
        file
        user {
          id
        }
      }
    }
  }
`;

export const queryConsortiumProperties = gql`
  query {
    consortiumPropertyList {
      id
      floor
      apartment
      type
      principal
    }
  }
`;

export const queryConsortiumTickets = gql`
  query {
    consortiumTicketList {
      id
      title
      description
      priority
      created
      budgets {
        id
        name
        file
      }
      user {
        id
        user {
          name
        }
        properties {
            id
            floor
            apartment
        }
      }
    }
  }
`;

export const queryConsortiumTicketsUser = gql`
  query {
    consortiumTicketListUser {
      id
      title
      description
      priority
      created
      budgets {
        id
        name
        file
      }
      user {
        id
        user {
          name
        }
        properties {
          id
          floor
          apartment
        }
      }
    }
  }
`;

export const mutationConsortiumPropertyCreate = gql`
  mutation ($input: CreateConsortiumPropertyInput!) {
    consortiumPropertyCreate(input: $input) {
      id
    }
  }
`;

export const mutationConsortiumTicketCreate = gql`
  mutation ($input: CreateConsortiumTicketInput!) {
    consortiumTicketCreate(input: $input) {
      id
    }
  }
`;

export const mutationConsortiumPropertyDelete = gql`
  mutation($id: ID!) {
    consortiumPropertyDelete(id: $id)
  }
`;

export const mutationConsortiumTicketDelete = gql`
  mutation($id: ID!) {
    consortiumTicketDelete(id: $id)
  }
`;

export const mutationConsortiumTicketMarkAsPriority = gql`
  mutation($id: ID!) {
    consortiumTicketMarkAsPriority(id: $id)
  }
`;
