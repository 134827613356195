import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';

export const KeywordPage = () => {

  const {
    register,
    handleSubmit,
  } = useForm();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();

  const onSubmit = (data: any) => {
    localStorage.setItem('password', data.keyword);
    navigate(-1);
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        {t('Keyword')}
      </div>
      <div>
        <input {...register('keyword')} required
          type={'password'}/>
      </div>
      <WButton
        text={t('Save')}
        type={'submit'}
      />
    </form>
    <BackButton/>
  </>;
};
