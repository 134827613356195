import {CardHeader} from '@mui/material';
import {UserImage} from '../../common/components/UserImage';
import {formatDate} from '../../common/utils/date';

export const ConsortiumUserCardHeader = (props: any) => {

  const {user, created} = props;

  const propertyLabel = () => {
    if (!user?.properties?.length) {
      return '';
    }
    let property = user.properties.filter(property => property.principal)[0] ?? null;
    if (!property) {
      property = user.properties[0];
    }
    return ` - ${property.floor}º${property.apartment}`;
  };

  const userLabel = () => {
    if (user?.user.name) {
      return user.user.name;
    } else {
      return 'Usuario eliminado';
    }
  };

  const roleLabel = () => {
    if (user?.role) {
      return ` - ${user.role}`;
    } else {
      return '';
    }
  };

  return <CardHeader
    avatar={
      <div className={'small-image-container'}>
        <UserImage user={user?.user}/>
      </div>
    }
    title={`${userLabel()}${propertyLabel()}${roleLabel()}`}
    subheader={formatDate(created)}
  />;
};
