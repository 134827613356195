import {useMutation, useQuery} from '@apollo/client';
import {TextField} from '@mui/material';
import React from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {ExpensesCostGroupEntity} from 'shared/entity/expenses/ExpensesCostGroupEntity';
import {ExpensesPropertyEntity} from 'shared/entity/expenses/ExpensesPropertyEntity';
import {
  mutationExpensesCostGroupCreate,
  mutationExpensesCostGroupDelete,
  queryExpensesCostGroupFind,
} from 'shared/queries/expenses/expensesCostGroupCrudQueries';
import {queryExpensesPropertyList} from 'shared/queries/expenses/expensesPropertyCrudQueries';
import {ExpensesCostInput} from '../../../../../src/modules/expenses/input/CreateExpensesCostGroupInput';
import {onError} from '../../../utils';
import {BackButton} from '../../common/components/BackButton';
import {CenterLoading} from '../../common/components/CenterLoading';
import {ErrorScreen} from '../../common/components/ErrorScreen';
import {WButton} from '../../common/components/WButton';

export const ExpensesCostGroupPage = () => {

  const navigate = useNavigate();
  const params = useParams();
  const {
    register,
    handleSubmit,
  } = useForm();
  const {t} = useTranslation();

  const {loading, data: data, error} = useQuery(queryExpensesCostGroupFind, {
    skip: !params.id,
    variables: {id: params.id},
  });
  const {
    loading: loadingProperties,
    data: dataProperties,
    error: errorProperties,
  } = useQuery(queryExpensesPropertyList);
  const [mutateFunction, {loading: loadingCreate}] = useMutation(mutationExpensesCostGroupCreate);
  const [mutateFunctionDelete, {loading: loadingDelete}] = useMutation(mutationExpensesCostGroupDelete);

  const costGroup: ExpensesCostGroupEntity = data?.expensesCostGroupFind;
  const properties: ExpensesPropertyEntity[] = dataProperties?.expensesPropertyList;

  const isLoading = () => {
    return loadingCreate || loadingDelete;
  };

  if (loading) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  const onSubmit = async (data: FieldValues) => {
    const newData = {
      name: data.name,
      costs: [] as ExpensesCostInput[],
    };
    delete data.name;
    for (const propertyField in data) {
      newData.costs.push({
        property: {
          id: propertyField,
        },
        amount: parseFloat(data[propertyField]),
      });
    }

    await mutateFunction({
      variables: {
        input: {
          ...{id: params.id},
          ...newData,
        },
      },
      onCompleted: () => {
        navigate(-1);
      },
      onError: onError,
    });
  };

  const remove = () => {
    mutateFunctionDelete({
      variables: {id: params.id},
      onCompleted: () => {
        toast.success('Eliminado');
        navigate(-1);
      },
    });
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className={'margin-1 w-100'}
        label={t('common.name')}
        {...register('name')}
        defaultValue={costGroup?.name}
      />
      <hr/>
      {properties?.map(e =>
        <div key={e.id}
          className={'margin-1'}>
          <TextField
            className={'w-100'}
            label={`${e.floor}º ${e.apartment}`}
            {...register(e.id)}
            defaultValue={costGroup?.costs.find(cost => cost.property.id === e.id)?.amount}
          />
        </div>,
      )}
      <WButton
        text={t('Save')}
        loading={isLoading()}
        type={'submit'}
      />
      {params.id && <WButton
        text={t('Delete')}
        loading={isLoading()}
        onClick={remove}
      />}
      <BackButton/>
    </form>
  </>;
};
