import { useQuery } from '@apollo/client';
import { SwipeableDrawer } from '@mui/material';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LabelEntity } from 'shared/entity/notes/LabelEntity';
import { WButton } from '../../common/components/WButton';
import { queryLabels } from '../queries';
import { Label } from './Label';

export const LabelsSidebar = forwardRef((props, ref) => {

  const {t} = useTranslation();
  const {
    data,
    loading,
  } = useQuery(queryLabels);

  const [state, setState] = React.useState(false);

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
        setState(open);
      };

  const open = () => {
    setState(true);
  };

  useImperativeHandle(ref, () => ({
    menu() {
      open();
    },
  }));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <><SwipeableDrawer
    anchor={'left'}
    open={state}
    onClose={toggleDrawer(false)}
    onOpen={() => {
      setState(true);
    }}>
    <div className={'menu-padding'}>
      {loading ? <>loading</> : <>
        {data && data.labels.map((label: LabelEntity) =>
          <Link key={label.id}
            to={'/label/' + label.id + '/notes'}>
            <Label label={label}/>
          </Link>)}
        {data && !data.labels.length && <>
          <div>{t('No labels')}</div>
          <WButton
            text={t('Create a label')}
            link={'/label'}
          />
        </>}
      </>}
    </div>
  </SwipeableDrawer>
  </>;
});
