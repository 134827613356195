import {useMutation} from '@apollo/client';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {mutationDeleteUser} from 'shared/queries/auth/queries';
import {ConfigContext} from '../../../Config';
import {onError} from '../../../utils';
import {WButton} from '../../common/components/WButton';
import {logout} from '../utils/logout';

export const DeleteUserButton = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const context = useContext(ConfigContext);

  const [loading, setLoading] = useState(false);

  const [mutateFunctionDeleteUser] = useMutation(mutationDeleteUser);

  const deleteUser = async (): Promise<void> => {
    const confirm: boolean = window.confirm(t('common.confirm') ?? undefined);
    if (!confirm) {
      return;
    }
    setLoading(true);
    const result = await mutateFunctionDeleteUser({
      onError,
    });
    setLoading(false);
    if (result.data?.deleteUser) {
      logout(false, navigate, context).then();
    }
  };

  return <WButton
    text={t('Delete user')}
    onClick={deleteUser}
    extra={{
      color: 'error',
    }}
    loading={loading}
  />;
};
