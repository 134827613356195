import {ApolloError, ServerError} from '@apollo/client';
import i18next from 'i18next';
import toast from 'react-hot-toast';
import {ConfigAction} from './Config';
import {config} from './config';
import jwt_decode from 'jwt-decode';
import {url} from './url';

export const delay = (ms: number = 0) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const requestMethods = {
  GET: 'GET',
  POST: 'POST',
};

export type TokenResponse = {
  token: string,
  refreshToken: string,
}

export const toUTC = (input: string) => {
  const date: Date = new Date(input);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const onError = (error: ApolloError) => {
  const networkError = error.networkError as ServerError;
  const message = networkError.result?.errors[0].message;
  onErrorMessage(message);
};

export const onErrorFetch = (error) => {
  const message = error.message;
  onErrorMessage(message);
};

export const connectionErrorMessage = () => {
  toast.error(i18next.t('errors.connectionError'));
};

const onErrorMessage = (message: string) => {
  if (message) {
    const translatedMessage = i18next.t(message);
    toast.error(translatedMessage);
  } else {
    connectionErrorMessage();
  }
};

export const setTokens = async (response, context) => {
  const json: TokenResponse = await response.json();
  localStorage.setItem(config.tokenLocalStorageVariable, json.token);
  localStorage.setItem(config.refreshTokenLocalStorageVariable, json.refreshToken);
  const {userId} = jwt_decode(json.token) as { userId: string };
  localStorage.setItem(config.userIdLocalStorageVariable, userId);
  if (!context) {
    return;
  }
  await context.dispatch({
    type: ConfigAction.loggedIn,
    value: true,
  });
  await context.dispatch({
    type: ConfigAction.userId,
    value: userId,
  });
  // await context.dispatch({
  //   type: ConfigAction.tokens,
  //   value: json,
  // });
};

export const restRequest = (setLoading: any, data: any, urlParameter: string, navigate?: any) => {
  setLoading(true);
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data),
  };
  fetch(`${url.apiUrl}/${urlParameter}`, requestOptions)
    .then(async response => {
      if (response.ok) {
        const json = await response.json();
        toast.success(i18next.t(json.message));
        if (navigate) {
          navigate('/', {replace: true});
        }
      } else {
        try {
          const json = await response.json();
          onErrorFetch(json);
        } catch {
          toast.error(response.statusText);
        }
      }
    })
    .catch(() => {
      connectionErrorMessage();
    })
    .finally(() => setLoading(false));
};
