import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';

export const NotificationsIndexPage = () => {

  const {t} = useTranslation();

  const check = () => {
    if (!('serviceWorker' in navigator)) {
      throw new Error('No Service Worker support!');
    }
    if (!('PushManager' in window)) {
      throw new Error('No Push API Support!');
    }
    toast('All good');
  };
  
  const registerServiceWorker = async () => {
    await navigator.serviceWorker.register('/assets/notifications/notificationSW.js');
    toast('Registered');
  };
  
  const requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission();
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission !== 'granted') {
      toast('Permission not granted');
    } else {
      toast('Permission granted');
    }
  };
  
  const sendToken = () => {
    const channel = new BroadcastChannel('communication');
    const token = localStorage.getItem('token');
    channel.postMessage(token);
    toast('Token posted to channel');
  };

  const removeServiceWorkers = () => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      console.log(registrations);
      for (const registration of registrations) {
        // console.log(registration);
        registration.unregister();
        toast('Registration deleted');
      }
    });
  };

  return <>
    <WButton
      text={t('Check')}
      onClick={check}
    />
    <WButton
      text={t('requestNotificationPermission')}
      onClick={requestNotificationPermission}
    />
    <WButton
      text={t('registerServiceWorker')}
      onClick={registerServiceWorker}
    />
    <WButton
      text={t('send token')}
      onClick={sendToken}
    />
    <WButton
      text={t('remove service workers')}
      onClick={removeServiceWorkers}
    />
    <BackButton/>
  </>;
};
