import React from 'react';
import { Route } from 'react-router-dom';
import { FollowingTextPostsPage } from './pages/FollowingTextPostsPage';
import { PhotosPage } from './pages/PhotosPage';
import { ProfilePage } from './pages/ProfilePage';
import { SearchUsersPage } from './pages/SearchUsersPage';
import { SocialFollowersPage } from './pages/SocialFollowersPage';
import { SocialFollowingPage } from './pages/SocialFollowingPage';
import { SocialIndexPage } from './pages/SocialIndexPage';
import { TextPostPage } from './pages/TextPostPage';
import { TextPostsPage } from './pages/TextPostsPage';

export const SocialRoutes = <>
  <Route path='social'
    element={<SocialIndexPage/>}/>
  <Route path='social/text-posts'
    element={<TextPostsPage/>}/>
  <Route path='social/text-posts/following'
    element={<FollowingTextPostsPage/>}/>
  <Route path='social/text-post'
    element={<TextPostPage/>}/>
  <Route path='social/text-post/:id'
    element={<TextPostPage/>}/>
  <Route path='social/search-users'
    element={<SearchUsersPage/>}/>
  <Route path='social/following'
    element={<SocialFollowingPage/>}/>
  <Route path='social/followers'
    element={<SocialFollowersPage/>}/>
  <Route path='social/photos'
    element={<PhotosPage/>}/>
  <Route path='social/photos/:id'
    element={<PhotosPage/>}/>
  <Route path='social/profile'
    element={<ProfilePage/>}/>
</>;
