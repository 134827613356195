import {FormControl, InputLabel, NativeSelect, OutlinedInput} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Gender} from '../../dating/pages/DatingSettingPage';

export const GenericSelect = (props: any) => {

  const {t} = useTranslation();

  const name = props.name;
  const label = `${t(props.translation ?? name)}`;
  const labelRequired = `${label}${props.required ? ' *' : ''}`;

  return <FormControl fullWidth
    className={'w-100 margin-1'}
  >
    <InputLabel
      variant='outlined'
      htmlFor={name}
    >
      {labelRequired}
    </InputLabel>
    <NativeSelect
      input={<OutlinedInput label={label}/>}
      inputProps={{
        id: name,
      }}
      {...props.register(name)}
      defaultValue={props.value}
      required={props.required}
    >
      <option></option>
      {props.options.map((option => <option key={option.value} value={option.value}>{option.text}</option>))}
    </NativeSelect>
  </FormControl>;
};
