import {useEffect, useMemo, useState} from 'react';
import {url} from '../../../url';
import {fetchFile} from '../../auth/utils/fetchFile';

export const ConsortiumBudgetLink = (props: any) => {
  const budget = props.budget;
  const ticket = props.ticket;

  const [link, setLink] = useState<string>();

  const userId = useMemo(() => localStorage.getItem('userId'), []);
  useEffect(() => {
    const entity = budget;
    const entityUser = {id: 'consortium'};
    // if (!entityUser) {
    //   return;
    // }
    fetchFile(entityUser.id, 'budget', entity.id, entity.file).then(value => {
      setLink(`${url.apiUrl}/${url.file}/consortium/${userId}/${entityUser.id}/${ticket.id}/budget/${entity.id}/${entity.file}?token=${value}`);
    });
  }, []);

  return <a href={link}
    target={'_blank'}
    rel='noreferrer'>
    {budget.name}
  </a>;
};
