import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutationCreateMessage, queryMessages } from 'shared/queries/chat/queries';
import { WButton } from '../../common/components/WButton';
import { MessageComponent } from '../components/MessageComponent';

const style = {
  messages: {
    overflow: 'scroll',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  inputButton: {
    margin: '0px',
  },
};

export const ConversationPage = () => {

  const params = useParams();
  const [messages, setMessages] = useState<any[]>([]);
  const userId = useMemo(() => localStorage.getItem('userId'), []);

  const {
    data,
    refetch,
  } = useQuery(queryMessages, {
    variables: {
      id: params.id,
    },
  });

  useEffect(() => {
    if (data?.messages) {
      setMessages(data.messages);
    }
  }, [data]);

  useEffect(() => {
    const poll = setInterval(() => {
      refetch();
      // bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 1000);
    // To stop interval on page exit:
    return () => {
      clearTimeout(poll);
    };
  }, []);

  const bottomRef = useRef<any>(null);

  const [text, setText] = useState<string>('');
  const [mutateFunctionCreate] = useMutation(mutationCreateMessage);
  const sendMessage = () => {
    if (!text) {
      return;
    }
    mutateFunctionCreate({
      variables: {
        input: {
          conversationId: params.id,
          text,
        },
      },
    });
    setMessages([{
      text, created: new Date(), user: {
        id: userId,
      },
    }, ...messages]);
    setText('');
  };

  useEffect(() => {
    // Separated because it won't work in the same hook
    bottomRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [messages]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return <>
    <div style={style.container as React.CSSProperties}>
      <div style={style.messages}>
        {messages.slice(0).reverse().map(message => <MessageComponent key={message.id}
          right={message.user.id === userId}
          message={message}/>)}
        <div ref={bottomRef}/>
      </div>
      <div style={style.inputContainer as React.CSSProperties}>
        <input value={text}
          onChange={event => setText(event.target.value)}
          onKeyDown={handleKeyDown}
        />
        <WButton
          noMargin={true}
          text={'➤'}
          onClick={sendMessage}
        />
      </div>
    </div>
  </>;
};
