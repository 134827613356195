import {useMutation} from '@apollo/client';
import {Delete, Edit, Favorite, Share} from '@mui/icons-material';
import {Avatar, Card, CardActions, CardContent, CardHeader, IconButton, Tooltip, Typography} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {TextPostEntity} from 'shared/entity/social/TextPostEntity';
import {url} from '../../../url';
import {fetchFile} from '../../auth/utils/fetchFile';
import {mutationDeleteTextPost} from '../postCrudMutations';
import {mutationLike, mutationUnlike, queryFollowingTextPosts, queryTextPosts} from '../queries';
import {handleSharing} from '../utils/handleSharing';

type TextPostProps = {
  textPost: TextPostEntity;
}

export const TextPost = (props: TextPostProps) => {

  const navigate = useNavigate();
  const entity = props.textPost;
  const [image, setImage] = useState<string>();
  const [imageProfile, setImageProfile] = useState<string>();
  const [mutateFunctionDelete] = useMutation(mutationDeleteTextPost, {
    variables: {
      id: props.textPost.id,
    },
    refetchQueries: [
      {query: queryTextPosts},
      {query: queryFollowingTextPosts},
    ],
  });
  const [mutateFunctionLike] = useMutation(mutationLike, {
    variables: {
      id: props.textPost.id,
    },
    refetchQueries: [
      {query: queryTextPosts},
      {query: queryFollowingTextPosts},
    ],
  });
  const [mutateFunctionUnlike] = useMutation(mutationUnlike, {
    refetchQueries: [
      {query: queryTextPosts},
      {query: queryFollowingTextPosts},
    ],
  });

  useEffect(() => {
    // fetchProfileImage(navigate, true, setImageProfile, entity.socialUser.id).then();
    // fetchPostImage(navigate, entity.file, setImage, entity.socialUser.id, entity.file).then();
    fetchFile(entity.socialUser.id, 'posts', entity.id, entity.file).then(value => {
      setImage(`${url.apiUrl}/${url.file}/${userId}/${entity.socialUser.id}/posts/${entity.id}/${entity.file}?token=${value}`);
    });
  }, []);
  // todo: this ok, I don't know why the warning

  useEffect(() => {
    const entityUser = entity.socialUser.user;
    if (!entityUser) {
      return;
    }
    fetchFile(entityUser.id, 'profile', entityUser.id, 'profile').then(value => {
      setImageProfile(`${url.apiUrl}/${url.file}/${userId}/${entityUser.id}/profile/${entityUser.id}/profile?token=${value}`);
    });
  }, []);

  const like = (): void => {
    mutateFunctionLike();
  };

  const unlike = (id: string): void => {
    mutateFunctionUnlike({
      variables: {id},
    });
  };

  const userId = useMemo(() => localStorage.getItem('userId'), []);
  const liked = useMemo(() => entity.likes.find(value => value.socialUser.id === userId), [
    entity.likes,
    userId,
  ]);

  const handleLike = () => {
    if (liked) {
      unlike(liked.id);
    } else {
      like();
    }
  };

  const listLikers = () => {
    return (entity.likes.reduce((previousValue, currentValue) => previousValue + currentValue.socialUser.user.name + ', ', '')).slice(0, -2);
  };

  return <Card className={'margin-1'}>
    <CardHeader
      avatar={
        <Avatar src={imageProfile}/>
      }
      title={props.textPost.socialUser.user.name}
      subheader={new Date(props.textPost.created).toLocaleString()}
      onClick={() => navigate(`/social/photos/${entity.socialUser.id}`)}
      className={'cursor-pointer'}
    />
    <CardContent>
      {props.textPost.file && <>
        {/*<img src={`${url.apiUrl}/file-posts-user/${props.textPost.socialUser.id}/posts/${props.textPost.file}`}*/}
        {/*  className={'w-100'}/>*/}
        <img src={image}
          className={'w-100'} alt={''}/>
        <hr/>
      </>}
      <Typography variant='body2'
        color='text.secondary'>
        {props.textPost.text}
      </Typography>
    </CardContent>
    <CardActions disableSpacing>
      <IconButton aria-label='add to favorites'
        onClick={() => handleLike()}>
        <Favorite color={liked ? 'error' : undefined}/>
      </IconButton>
      <Tooltip title={listLikers()}>
        <h3>{entity.likes.length}</h3>
      </Tooltip>
      <Link to={`/social/text-post/${entity.id}`}>
        <IconButton aria-label='add to favorites'>
          <Edit/>
        </IconButton>
      </Link>
      <IconButton aria-label='share'
        onClick={() => mutateFunctionDelete()}>
        <Delete/>
      </IconButton>
      <IconButton aria-label='share'
        onClick={() => handleSharing(entity.text)}>
        <Share/>
      </IconButton>
    </CardActions>
  </Card>;
};
