import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type ConsortiumPropertyProps = {
  property: any;
}

const styles = {
  card: {
    padding: 20,
    cursor: 'pointer',
  },
};

export const ConsortiumProperty = (props: ConsortiumPropertyProps) => {

  const navigate = useNavigate();
  const {t} = useTranslation();

  const property = props.property;

  const typeToText = {
    OWNER: t('modules.consortium.owner'),
    TENANT: t('modules.consortium.tenant'),
  };

  return <Card className={'margin-1'}
    style={styles.card}
    onClick={() => navigate(`/consortium/property/${property.id}`)}
  >
    {property.floor} {property.apartment} – {typeToText[property.type]}
  </Card>;
};
