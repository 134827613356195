import { useMutation, useQuery } from '@apollo/client';
import { TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toUTC } from '../../../utils';
import { GenderSelect } from '../../common/components/GenderSelect';
import { WButton } from '../../common/components/WButton';
import { submitWrapper } from '../../common/utils/submitWrapper';
import { getGeolocation } from '../../geo/utils/geo';
import { mutationCreateProfile } from '../profileCrudMutations';
import { queryProfile } from '../profileQueries';

export const ProfilePage = () => {
  const {
    register,
    handleSubmit,
  } = useForm();

  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const [birthdate, setBirthdate] = useState<Date | null>(toUTC('1990-01-01'));
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');

  const {
    data,
    loading: loadingProfile,
  } = useQuery(queryProfile, {
    onCompleted: (data) => {
      setBirthdate(toUTC(data.profile.birthdate));
      setLatitude(data.profile.latitude ?? '');
      setLongitude(data.profile.longitude ?? '');
    },
  });
  const [mutateFunctionCreate] = useMutation(mutationCreateProfile);

  const onSubmit = async (data) => {
    const options = {
      variables: {
        input: {
          ...data,
          birthdate,
          gender: data.gender.length ? data.gender : undefined,
          latitude: typeof latitude === 'number' ? latitude : undefined,
          longitude: typeof longitude === 'number' ? longitude : undefined,
        },
      },
    };
    await submitWrapper(mutateFunctionCreate, options, setLoading);
  };

  const geolocate = () => {
    const success = (position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    };
    getGeolocation(success);
  };

  return <>{!loadingProfile &&
    <form onSubmit={handleSubmit(onSubmit)}>
      <MobileDatePicker
        inputFormat={'yyyy-MM-DD'}
        toolbarFormat={'yyyy-MM-DD'}
        disableFuture
        label={t('Birthdate')}
        className={'w-100 margin-1'}
        value={birthdate}
        onChange={(value) => setBirthdate(value)}
        renderInput={(params) => <TextField {...params} />}
      />
      <GenderSelect register={register} gender={data?.profile.gender}/>
      <TextField
        label={t('Description')}
        variant='outlined'
        className={'w-100 margin-1'}
        {...register('description')}
        multiline
        defaultValue={data?.profile.description}
      />
      <TextField
        label={t('Latitude')}
        variant='outlined'
        className={'w-100 margin-1'}
        value={latitude}
        disabled
      />
      <TextField
        label={t('Longitude')}
        variant='outlined'
        className={'w-100 margin-1'}
        value={longitude}
        disabled
      />
      <WButton
        text={t('Geolocate')}
        onClick={geolocate}
      />
      <WButton
        text={t('Save')}
        disabled={loading}
        loading={loading}
        type={'submit'}
      />
    </form>
  }</>;
};
