import { App as CapacitorApp } from '@capacitor/app';

export const capacitorSetup = () => {
  CapacitorApp.addListener('backButton', ({canGoBack}) => {
    if (!canGoBack) {
      CapacitorApp.exitApp().then();
    } else {
      window.history.back();
    }
  });
};
