import {useContext, useEffect} from 'react';
import {toast} from 'react-hot-toast';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ConfigContext} from '../../../Config';
import {url} from '../../../url';
import {connectionErrorMessage, onError, onErrorFetch, setTokens} from '../../../utils';
import {CenterLoading} from '../../common/components/CenterLoading';

export const SocialPage = () => {

  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const context = useContext(ConfigContext);

  useEffect(() => {
    const code = searchParams.get('code');
    fetch(`${url.apiUrl}/oauth2/redirect/${params.service}?code=${code}`)
      .then(async response => {
        if (response.ok) {
          await setTokens(response, context);
        } else {
          try {
            const json = await response.json();
            onErrorFetch(json);
          } catch {
            toast.error(response.statusText);
          }
        }
      })
      .catch(() => {
        connectionErrorMessage();
      })
      .finally(() => {
        navigate('/', {replace: true});
      });
  }, [
    params,
    searchParams,
    navigate,
  ]);

  return <CenterLoading/>;
};
