import {useMutation} from '@apollo/client';
import {Avatar} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {SocialUserEntity} from 'shared/entity/social/SocialUserEntity';
import {url} from '../../../url';
import {fetchFile} from '../../auth/utils/fetchFile';
import {WButton} from '../../common/components/WButton';
import {
  mutationFollow,
  mutationUnfollow,
  querySearchUsers,
  querySocialFollowers,
  querySocialFollowersCounters,
  querySocialFollowing,
} from '../queries';

type SocialUserCardProps = {
  socialUser: SocialUserEntity
}

export const SocialUserCard = (props: SocialUserCardProps) => {

  const navigate = useNavigate();
  const {t} = useTranslation();
  const socialUser = props.socialUser;
  const [mutateFunctionFollow] = useMutation(mutationFollow);
  const [mutateFunctionUnfollow] = useMutation(mutationUnfollow);
  const [image, setImage] = useState<string>();

  const follow = (id: string) => {
    mutateFunctionFollow({
      variables: {
        id,
      },
      refetchQueries: [
        querySearchUsers,
        {query: querySocialFollowersCounters},
        {query: querySocialFollowers},
        {query: querySocialFollowing},
      ],
    });
  };

  const unfollow = (id: string) => {
    mutateFunctionUnfollow({
      variables: {
        id,
      },
      refetchQueries: [
        querySearchUsers,
        {query: querySocialFollowersCounters},
        {query: querySocialFollowers},
        {query: querySocialFollowing},
      ],
    });
  };

  const userId = useMemo(() => localStorage.getItem('userId'), []);
  useEffect(() => {
    // fetchProfileImage(navigate, true, setImage, socialUser.id).then();
    const entity = socialUser.user;
    if (!entity) {
      return;
    }
    fetchFile(entity.id, 'profile', entity.id, 'profile').then(value => {
      setImage(`${url.apiUrl}/${url.file}/${userId}/${entity.id}/profile/${entity.id}/profile?token=${value}`);
    });
  }, []);

  return <div
    className={'d-flex flex-row social-user-card'}
  >
    <div
      className={'d-flex flex-row header'}
      onClick={() => navigate(`/social/photos/${socialUser.id}`)}
    >
      <Avatar src={image}/>
      <div className={'name'}>{socialUser.user.name}</div>
    </div>
    <div style={{flexGrow: 1}}></div>
    {!socialUser.followed ?
      <WButton
        text={t('Follow')}
        onClick={() => follow(socialUser.id)}
        noMargin={true}
      /> :
      <WButton
        text={t('Unfollow')}
        onClick={() => unfollow(socialUser.id)}
        noMargin={true}
      />}
  </div>;
};
