import {Avatar} from '@mui/material';
import imageCompression from 'browser-image-compression';
import Compressor from 'compressorjs';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {url} from '../../../url';
import {UserImage} from '../../common/components/UserImage';
import {WButton} from '../../common/components/WButton';
import {fetchFile} from '../utils/fetchFile';

export const ChangeUserImage = (props: any) => {
  const {t} = useTranslation();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [image, setImage] = useState<string>();

  // const [loadingImage, setLoadingImage] = useState<boolean>();
  // const [image, setImage] = useState<string>('/assets/default.svg');

  const user = props.user;

  // const userId = useMemo(() => localStorage.getItem('userId'), []);
  // useEffect(() => {
  //   // fetchProfileImage(navigate, dataUser, setImage, dataUser?.user.id, setLoadingImage).then();
  //   const entity = dataUser?.user;
  //   if (!entity) {
  //     return;
  //   }
  //   fetchFile(entity.id, 'profile', entity.id, 'profile').then(value => {
  //     setImage(`${url.apiUrl}/${url.file}/${userId}/${entity.id}/profile/${entity.id}/${entity.imageFile}?token=${value}`);
  //   });
  // }, [dataUser]);

  useEffect(() => {
    if (!user?.imageFile) {
      setImage(undefined);
      return;
    }
    fetchFile(user.id, 'profile', user.id, user.imageFile).then(value => {
      setImage(`${url.apiUrl}/${url.file}/${user.id}/${user.id}/profile/${user.id}/${user.imageFile}?token=${value}`);
    });
  }, [
    user,
  ]);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };
    return imageCompression(file, options);
  };

  const compressImage2 = async (file) => {
    const compress = new Promise((resolve, reject) => {
      new Compressor(file, {
        width: 500,
        convertSize: 1000000 * 1, // mb
        success: resolve,
        error: reject,
      });
    });
    return await compress as File;
  };

  const changeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const compressedFile = await compressImage2(file);
    props.setFile(compressedFile);
    if (file) {
      setImage(URL.createObjectURL(compressedFile));
    }
  };

  const changePicture = () => {
    inputFileRef.current?.click();
  };

  return <>
    <div className={'profile-picture-container margin-1'}>
      {/*{loadingImage && <CircularProgress/>}*/}
      {/*{!loadingImage && <img alt=''*/}
      {/*  src={image}*/}
      {/*  className={'h-100 profile-picture'}/>}*/}
      <div className={'profile-picture'}>
        {image && <Avatar src={image}/>}
        {!image && <UserImage/>}
      </div>
    </div>
    <WButton
      text={t('Change picture')}
      onClick={changePicture}
    />
    <input type='file'
      ref={inputFileRef}
      onChange={changeHandler}
      accept={'image/*'}
    />
  </>;
};
