import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { WButton } from './WButton';

export const BackButton = () => {
  const navigate = useNavigate();

  return <WButton
    text={t('Back')}
    variant={'outlined'}
    onClick={() => navigate(-1)}
  />;
};
