import {Apps, config} from './config';
import {IndexPage} from './IndexPage';
import {ConsortiumIndexPage} from './modules/consortium/ConsortiumIndexPage';

export const IndexRedirector = () => {
  if (config.app === Apps.ENCONSORCIO) {
    return <ConsortiumIndexPage/>;
  }
  return <IndexPage/>;
};
