import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../url';
import {connectionErrorMessage, onErrorFetch} from '../../../utils';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';

export const RegisterPage = () => {
  const {
    register,
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const {t} = useTranslation();

  const onSubmit = (data: any) => {
    setLoading(true);
    // data.password = hashPassword(data.password);
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
      // credentials: 'include' as RequestCredentials,
    };
    fetch(`${url.apiUrl}/${url.register}`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          toast.success(t('modules.auth.userCreated'));
          navigate('/', {replace: true});
        } else {
          try {
            const json = await response.json();
            onErrorFetch(json);
          } catch {
            toast.error(response.statusText);
          }
        }
      })
      .catch(() => {
        connectionErrorMessage();
      })
      .finally(() => setLoading(false));
  };

  return <>
    <h1>{t('Register')}</h1>
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={t('Email')}
        variant='outlined'
        className={'w-100 margin-1'}
        required
        type={'email'}
        {...register('user')}
      />
      <TextField
        label={t('Password')}
        variant='outlined'
        className={'w-100 margin-1'}
        required
        type={'password'}
        {...register('password')}
      />
      <TextField
        label={t('Name')}
        variant='outlined'
        className={'w-100 margin-1'}
        required
        {...register('name')}
      />
      <WButton
        text={t('Register')}
        disabled={loading}
        loading={loading}
        type={'submit'}
        extra={{
          'data-testid': 'register',
        }}
      />
    </form>
    <BackButton/>
  </>;
};
