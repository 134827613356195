import {useMutation, useQuery} from '@apollo/client';
import {CheckCircleOutline} from '@mui/icons-material';
import {Chip} from '@mui/material';
import {useState} from 'react';
import {
  mutationConsortiumPollVoteCreate,
  mutationConsortiumPollVoteDelete,
  queryConsortiumPollVotes,
} from 'shared/queries/consortium/queriesPollVote';
import {onError} from '../../../utils';
import {WButton} from '../../common/components/WButton';
import {ConsortiumVoters} from './ConsortiumVoters';

export const ConsortiumPollOptions = (props: any) => {
  const poll = props.poll;

  const [voting, setVoting] = useState(false);

  const {loading, data: dataVotes} = useQuery(queryConsortiumPollVotes, {
    variables: {
      input: {
        pollId: poll.id,
      },
    },
  });
  const [mConsortiumPollVoteCreate] = useMutation(mutationConsortiumPollVoteCreate);
  const [mConsortiumPollVoteDelete] = useMutation(mutationConsortiumPollVoteDelete);

  const votes = dataVotes?.consortiumPollVotes;

  const getVotes = (option) => {
    return votes?.find(item => item.optionId === option.id);
  };

  const vote = async (option) => {
    setVoting(true);
    await mConsortiumPollVoteCreate({
      variables: {
        input: {
          optionId: option.id,
          pollId: poll.id,
        },
      },
      onError,
      refetchQueries: [
        queryConsortiumPollVotes,
      ],
    });
    setVoting(false);
  };

  const unvote = async (option) => {
    setVoting(true);
    await mConsortiumPollVoteDelete({
      variables: {
        id: option.id,
      },
      onError,
      refetchQueries: [
        queryConsortiumPollVotes,
      ],
    });
    setVoting(false);
  };

  return <div className={'w-100'}>
    {poll.anonymous ?
      <Chip label='Votación anónima'
        className={'mr-5'}
        size='small'
        color='info'
        variant='outlined'
      />
      : <Chip label='Votación no anónima'
        className={'mr-5'}
        size='small'
        color='info'
        variant='outlined'
      />}
    {poll.multipleResponses && <Chip label='Opciones múltiples'
      className={'mr-5'}
      size='small'
      color='info'
      variant='outlined'
    />}
    {poll.options.map(option =>
      <div key={option.id}
        className={'d-flex align-items-center justify-content-space-between margin-1'}>
        <div className={'d-flex gap-10'}>
          <div>
            <div>{option.title}</div>
            <div>Votos: {votes ? getVotes(option)?.votes ?? 0 : '...'}</div>
            <ConsortiumVoters users={getVotes(option)?.users}/>
          </div>
          {getVotes(option)?.votedByUser && <CheckCircleOutline className={'align-self-center'}/>}
        </div>
        {!getVotes(option)?.votedByUser ?
          <WButton
            text={'Votar'}
            onClick={() => vote(option)}
            noMargin
            loading={voting}
          /> :
          <WButton
            text={'Deshacer voto'}
            onClick={() => unvote(option)}
            noMargin
            loading={voting}
          />}
      </div>,
    )}
  </div>;
};
