import {gql} from '@apollo/client';

export const mutationExpensesDataGroupCreate = gql`
  mutation ($input: CreateExpensesDataGroupInput!) {
    expensesDataGroupCreate(input: $input) {
      id
    }
  }
`;

export const mutationExpensesDataGroupDelete = gql`
  mutation($id: ID!) {
    expensesDataGroupDelete(id: $id)
  }
`;

export const queryExpensesDataGroupFind = gql`
  query($id: ID!) {
    expensesDataGroupFind(id: $id) {
      id
      name
      data {
        id
        name
        text
      }
    }
  }
`;

export const queryExpensesDataGroupList = gql`
  query {
    expensesDataGroupList {
      id
      name
    }
  }
`;
