import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WButton } from '../../common/components/WButton';
import { PhotoComponent } from '../components/PhotoComponent';
import { mutationCreatePhoto, queryPhotos } from '../photoQueries';
import { PhotoEntity } from 'shared/entity/social/PhotoEntity';

export const PhotosPage = () => {

  const {t} = useTranslation();
  const [mutateFunctionCreate] = useMutation(mutationCreatePhoto);
  const params = useParams();
  const editable = useMemo(() => {
    return !Object.keys(params).length || params.id === localStorage.getItem('userId');
  }, [params]);
  const {
    data,
    loading,
  } = useQuery(queryPhotos, {
    variables: {
      id: params.id,
    },
  });

  const changePicture = () => {
    inputFileRef.current?.click();
  };
  const inputFileRef = useRef<HTMLInputElement>(null);

  const changeHandler = (event: any) => {
    const file = event.target.files[0];
    mutateFunctionCreate({
      variables: {
        file,
      },
      refetchQueries: [
        queryPhotos,
      ],
    });
  };

  return <>
    {loading && <div className={'center'}>
      <CircularProgress/>
    </div>}
    {editable && <WButton
      text={t('Create')}
      onClick={changePicture}
    />}
    <input type='file'
      ref={inputFileRef}
      onChange={changeHandler}
    />
    {!data?.photos.length && t('No photos')}
    {data?.photos.map((photo: PhotoEntity) => <div key={photo.id} className={'col-33'}>
      <PhotoComponent photo={photo}
        editable={editable}/>
    </div>)}
  </>;
};
