import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

/*
MDButton stands for Material Design Button
 */
export const MDButton = (props: any) => {

  const loadingClass = (props.loading ? ' loading' : '');
  const classes = 'w-100' + loadingClass + ' ' + props.classes;

  let component = <Button
    variant={props.variant || 'contained'}
    className={classes}
    onClick={props.onClick}
    type={props.type || 'button'}
    disabled={props.disabled || props.loading}
    color={props.link ? 'info' : 'primary'}
    style={{
      textTransform: 'none',
    }}
    {...props.extra}
  >
    {props.text}
  </Button>;

  if (props.label) {
    component = <>{props.label}<div className={'margin-1'}>{component}</div></>;
  }

  if (props.link) {
    component = <Link
      className={'t-a'}
      to={props.link}
      tabIndex={-1}>
      {component}
    </Link>;
  }

  component = <div className={props.noMargin ? '' : 'margin-1'}>{component}</div>;

  return component;
};
