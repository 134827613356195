import { useMutation } from '@apollo/client';
import { UserEntity } from 'shared/entity/auth/UserEntity';
import { mutationMatch } from 'shared/queries/dating/queries';
import { WButton } from '../../common/components/WButton';

type CandidateCardProps = {
  user: UserEntity
}

export const CandidateCard = ({user}: CandidateCardProps) => {

  const [mutateFunctionMatch] = useMutation(mutationMatch, {
    variables: {
      id: user.id,
    },
  });

  return <div key={user.id}>
    {user.name}: {user.socialUser.socialUserProfile.description}
    <WButton
      text={'Like'}
      onClick={mutateFunctionMatch}
    />
  </div>;
};
