import {useQuery} from '@apollo/client';
import {ReactElement, useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {queryUserEnabled} from 'shared/queries/auth/queries';
import {onError} from '../../../utils';
import {CenterLoading} from '../../common/components/CenterLoading';
import {ErrorScreen} from '../../common/components/ErrorScreen';
import {UserNotEnabledScreen} from '../../common/components/UserNotEnabledScreen';

export const UserEnabledWrapper = (): ReactElement => {
  const [enabled, setEnabled] = useState<boolean>();
  const location = useLocation();

  const {
    loading,
    data,
    error,
  } = useQuery(queryUserEnabled, {
    onError: onError,
  });

  const user = data?.user;

  useEffect(() => {
    const inUserPage = location.pathname === '/user';
    const inChangePasswordPage = location.pathname === '/auth/changePassword';
    setEnabled(inUserPage || inChangePasswordPage || user?.enabled);
  }, [
    location,
    user,
  ]);

  if (loading
    || enabled == undefined // to avoid flash
  ) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  return enabled ? <Outlet/> : <UserNotEnabledScreen/>;
};
