import { useMutation, useQuery } from '@apollo/client';
import { Slider } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mutationCreateDatingSetting, queryDatingSetting } from 'shared/queries/dating/datingSettingCrudMutations';
import { GenderSelect } from '../../common/components/GenderSelect';
import { WButton } from '../../common/components/WButton';
import { submitWrapper } from '../../common/utils/submitWrapper';

// todo: see why this cannot be imported from shared
export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export const DatingSettingPage = () => {
  const {
    register,
    handleSubmit,
  } = useForm();

  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const [range, setRange] = useState<number>(5);
  const [age, setAge] = useState<number[]>([
    25,
    30,
  ]);

  const {
    data,
    loading: loadingProfile,
  } = useQuery(queryDatingSetting, {
    onCompleted: () => {
      setRange(data.datingSetting.range);
      setAge([
        data.datingSetting.minAge,
        data.datingSetting.maxAge,
      ]);
    },
  });
  const [mutateFunctionCreate] = useMutation(mutationCreateDatingSetting);

  const onSubmit = async (data) => {
    const options = {
      variables: {
        input: {
          minAge: age[0],
          maxAge: age[1],
          range,
          gender: data.gender.length ? data.gender : undefined,
        },
      },
    };
    await submitWrapper(mutateFunctionCreate, options, setLoading);
  };

  const handleChangeRange = (event: Event, newValue: number | number[]) => {
    setRange(newValue as number);
  };

  const handleChangeAge = (event: Event, newValue: number | number[]) => {
    setAge(newValue as number[]);
  };

  return <>{!loadingProfile &&
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>{t('Range')}</h2>
      <Slider
        value={range}
        onChange={handleChangeRange}
        min={1}
        max={100}
        valueLabelDisplay={'auto'}
      />
      <p>{'<'} {range} km</p>
      <h2>{t('Age')}</h2>
      <Slider
        value={age}
        onChange={handleChangeAge}
        min={18}
        max={100}
        disableSwap
      />
      <p>Min: {age[0]}</p>
      <p>Max: {age[1]}</p>
      <h2>{t('Gender')}</h2>
      <GenderSelect register={register} gender={data?.datingSetting.gender}/>
      <WButton
        text={t('Save')}
        disabled={loading}
        loading={loading}
        type={'submit'}
      />
    </form>
  }</>;
};
