import { gql } from '@apollo/client';

export const mutationCreateTextPost = gql`
  mutation ($input: CreateTextPostInput!, $file: Upload) {
    createTextPost(input: $input, file: $file) {
      id
      text
    }
  }
`;

export const mutationUpdateTextPost = gql`
  mutation ($input: UpdateTextPostInput!, $file: Upload) {
    updateTextPost(input: $input, file: $file) {
      id
      text
    }
  }
`;

export const mutationDeleteTextPost = gql`
  mutation ($id: ID!) {
    deleteTextPost(id: $id)
  }
`;
