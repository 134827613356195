import {useQuery} from '@apollo/client';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {queryConsortiumProperties} from 'shared/queries/consortium/queries';
import {Apps, config } from '../../config';
import {BackButton} from '../common/components/BackButton';
import {CenterLoading} from '../common/components/CenterLoading';
import {ErrorScreen} from '../common/components/ErrorScreen';
import {WButton} from '../common/components/WButton';

export const ConsortiumIndexPage = () => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const {loading: loadingProperties, data, error} = useQuery(queryConsortiumProperties);

  const properties = data?.consortiumPropertyList;

  const loading = loadingProperties || !properties.length;

  useEffect(() => {
    if (properties && !properties.length) {
      navigate('/consortium/properties');
    }
  }, [properties]);

  if (loading) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  return <>
    {/*<WButton*/}
    {/*  text={t('modules.consortium.property')}*/}
    {/*  link={'property'}*/}
    {/*/>*/}
    <WButton
      text={t('modules.consortium.properties')}
      link={'/consortium/properties'}
    />
    {/*<WButton*/}
    {/*  text={t('modules.consortium.ticket')}*/}
    {/*  link={'ticket'}*/}
    {/*/>*/}
    {config.app === Apps.DEVELOPER && <WButton
      text={t('modules.consortium.tickets')}
      link={'/consortium/tickets'}
    />}
    {config.app === Apps.DEVELOPER && <WButton
      text={t('modules.consortium.ticketsUser')}
      link={'/consortium/tickets/user'}
    />}
    <WButton
      text={t('modules.consortium.polls')}
      link={'/consortium/polls'}
    />
    {/*<BackButton/>*/}
  </>;
};
