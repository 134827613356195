import { useQuery } from '@apollo/client';
import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextPost } from '../components/TextPost';
import { queryFollowingTextPosts } from '../queries';
import { TextPostPage } from './TextPostPage';

export const FollowingTextPostsPage = () => {

  const {t} = useTranslation();
  const {
    data,
    loading,
  } = useQuery(queryFollowingTextPosts, {
    fetchPolicy: 'network-only',
  });

  return <>
    <h1>{t('Posts')}</h1>
    <Card>
      <CardContent>
        <h2>{t('Create a post')}</h2>
        <TextPostPage/>
      </CardContent>
    </Card>
    {loading ? <>loading</> : <>
      <div>
        {data ? (Object.values(data)[0] as Array<any>).map((item: any) =>
          <TextPost textPost={item}
            key={item.id}/>) : null}
      </div>
    </>}
  </>;
};
