import { useQuery } from '@apollo/client';
import { Alert } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryConsortiumProperties } from 'shared/queries/consortium/queries';
import { BackButton } from '../../common/components/BackButton';
import {CenterLoading} from '../../common/components/CenterLoading';
import { ErrorScreen } from '../../common/components/ErrorScreen';
import { WButton } from '../../common/components/WButton';
import { ConsortiumProperty } from '../components/ConsortiumProperty';

export const ConsortiumPropertiesPage = () => {

  const {t} = useTranslation();

  const {loading, data, error} = useQuery(queryConsortiumProperties);

  if (loading) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  const properties = data.consortiumPropertyList;

  return <>
    {!properties.length &&
      <div className={'margin-1'}>
        <Alert severity="info" variant="filled">El primer paso para empezar a usar la app es agregar su propiedad. Presione el botón para continuar.</Alert>
      </div>
    }
    {properties.length == 1 &&
      <div className={'margin-1'}>
        <Alert severity="info" variant="filled">Ya agregó su propiedad. Puede agregar más propiedades (si posee) o presionar Volver para ir al menú principal.</Alert>
      </div>
    }
    {/*{!properties.length &&*/}
    {/*  <div className={'margin-1'}>No has agregado ninguna propiedad.</div>*/}
    {/*}*/}
    {properties.map(property => <ConsortiumProperty key={property.id} property={property}/>)}
    <WButton
      text={t('modules.consortium.addProperty')}
      link={'/consortium/property'}
    />
    {!!properties.length && <BackButton/>}
  </>;
};
