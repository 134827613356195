import {TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {WButton} from '../../common/components/WButton';

type ExpensesDataComponentProps = {
  onDelete?: any
  data: any
}

export const ExpensesDataComponent = (props: ExpensesDataComponentProps) => {

  const {t} = useTranslation();

  const data = props.data;

  return <>
    <TextField
      className={'margin-1 w-100'}
      label={t('common.name')}
      onChange={value => data.name = value.target.value}
      defaultValue={data.name}
    />
    <TextField
      className={'margin-1 w-100'}
      label={t('text')}
      onChange={value => data.text = value.target.value}
      defaultValue={data.text}
    />
    <WButton
      text={t('Eliminar dato')}
      onClick={() => props.onDelete(props.data.id)}
    />
    <hr/>
  </>;
};
