export const TestPage = () => {
  return <>
    <div className={'custom-font'}>Custom font</div>
    <input type={'submit'} value={'Input type submit'}></input>
    <button>Button</button>
    <button className={'btn-warning'}>Button</button>
    <button className={'btn-danger'}>Button</button>
    <button className={'loading'}>Loading</button>
    <button className={'btn-danger loading'}>Loading</button>
    <button disabled>Disabled</button>
    <button className={'btn-danger'} disabled>Disabled</button>
    <button className={'loading'} disabled>Loading disabled</button>
    <button className={'btn-danger loading'} disabled>Loading disabled</button>
    <input></input>
    <input className={'loading'}></input>
    <input disabled></input>
    <input className={'loading'} disabled></input>
    <select></select>
    <select className={'loading'}></select>
    <select disabled></select>
    <select className={'loading'} disabled></select>
    <hr></hr>
    <textarea></textarea>
  </>;
};
