import {useMutation, useQuery} from '@apollo/client';
import {Add} from '@mui/icons-material';
import {Fab, TextField} from '@mui/material';
import {useForm} from 'react-hook-form';
import {mutationLogmateLogCreate, queryLogmateLogList} from 'shared/queries/logmate/logmateLogCrudQueries';
import {LogmateLogEntity} from '../../../../shared/entity/logmate/LogmateLogEntity';
import {onError} from '../../utils';
import {LogmateLogList} from './components/LogmateLogList';

export const LogmateIndexPage = () => {

  const {
    register,
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const {loading, data, error} = useQuery(queryLogmateLogList);
  const [mutateFunction, {loading: loadingCreate}] = useMutation(mutationLogmateLogCreate);

  const logs = data?.logmateLogList;

  const onSubmit = async (data) => {
    const mutation = await mutateFunction({
      variables: {
        input: data,
      },
      onError,
      refetchQueries: [queryLogmateLogList],
    });
    if (!mutation.errors) {
      reset();
    }
  };

  const onClickLog = (log: LogmateLogEntity) => {
    setValue('name', log.name);
  };

  return <>
    <div className={'margin-1'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'margin-2'}>
          <TextField
            variant='outlined'
            {...register('name')}
            className={'w-100'}
            required
          />
        </div>
        <div className={'margin-2'}>
          <TextField
            variant='outlined'
            {...register('description')}
            className={'w-100'}
          />
        </div>
        <div className={'margin-2'}
          style={{textAlign: 'right'}}
        >
          <Fab color='primary'
            type={'submit'}
          >
            <Add/>
          </Fab>
        </div>
      </form>
      <LogmateLogList logs={logs}
        onClick={onClickLog}/>
    </div>
  </>;
};
