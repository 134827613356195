import {useMutation} from '@apollo/client';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PhotoEntity} from 'shared/entity/social/PhotoEntity';
import {url} from '../../../url';
import {fetchFile} from '../../auth/utils/fetchFile';
import {WButton} from '../../common/components/WButton';
import {mutationDeletePhoto, queryPhotos} from '../photoQueries';

type PhotoComponentProps = {
  photo: PhotoEntity
  editable: boolean
}

export const PhotoComponent = (props: PhotoComponentProps) => {

  const navigate = useNavigate();
  const {t} = useTranslation();
  const photo = props.photo;
  const [image, setImage] = useState<string>();

  const entity = photo;

  const userId = useMemo(() => localStorage.getItem('userId'), []);

  useEffect(() => {
    // fetchPhotoImage(navigate, photo.file, setImage, photo.socialUser.id, photo.file).then();
    fetchFile(entity.socialUser.id, 'photos', entity.id, entity.file).then(value => {
      setImage(`${url.apiUrl}/${url.file}/${userId}/${entity.socialUser.id}/photos/${entity.id}/${entity.file}?token=${value}`);
    });
  }, [
    photo.file,
    photo.socialUser.id,
  ]);

  const [mutateFunctionDelete] = useMutation(mutationDeletePhoto, {
    variables: {
      id: photo.id,
    },
    refetchQueries: [
      queryPhotos,
    ],
  });

  return <>
    <div className={'margin-1'}>
      <img src={image}
        alt={''}
        className={'w-100'}
      />
      {props.editable && <WButton
        text={t('Delete')}
        onClick={mutateFunctionDelete}
        noMargin={true}
      />}
    </div>

  </>;
};
