import React from 'react';
import { Route } from 'react-router-dom';
import { KeywordPage } from './pages/KeywordPage';
import { LabelNotesPage } from './pages/LabelNotesPage';
import { LabelPage } from './pages/LabelPage';
import { LabelsPage } from './pages/LabelsPage';
import { NotePage } from './pages/NotePage';
import { NotesPage } from './pages/NotesPage';

export const NotesRoutes = <>
  <Route path='notes' element={<NotesPage/>}/>
  <Route path='note' element={<NotePage/>}/>
  <Route path='note/:id' element={<NotePage/>}/>
  <Route path='labels' element={<LabelsPage/>}/>
  <Route path='label' element={<LabelPage/>}/>
  <Route path='label/:id' element={<LabelPage/>}/>
  <Route path='label/:id/notes' element={<LabelNotesPage/>}/>
  <Route path='keyword' element={<KeywordPage/>}/>
</>;
