import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';
import { queryLabels } from '../queries';
import { Label } from '../components/Label';

export const LabelsPage = () => {

  const {t} = useTranslation();

  const {
    data,
    loading,
  } = useQuery(queryLabels, {
    fetchPolicy: 'network-only',
  });

  return <>
    <h1>{t('Labels')}</h1>
    <BackButton/>
    <WButton
      text={t('Create a label')}
      link={'/label'}
    />
    {loading ? <>loading</> : <>
      {data && !data.labels.length && <>{t('No labels')}</>}
      <div className={'flex'}>
        {data ? data.labels.map((label: any) =>
          <Link key={label.id}
            to={'/label/' + label.id}>
            <Label label={label}/>
          </Link>) : null}
      </div>
    </>}
  </>;
};
