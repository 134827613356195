import { ServerError } from '@apollo/client';
import toast from 'react-hot-toast';

export const submitWrapper = async (mutation, options, setLoading) => {
  setLoading(true);
  try {
    await mutation(options);
  } catch (error: any) {
    const networkError: ServerError = error.networkError;
    toast.error(networkError.message);
  }
  setLoading(false);
};
