import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../common/components/BackButton';
import { WButton } from '../common/components/WButton';

export const DatingIndexPage = () => {

  const {t} = useTranslation();

  return <>
    <WButton
      text={t('Settings')}
      link={'settings'}
    />
    <WButton
      text={t('Candidates')}
      link={'candidates'}
    />
    <BackButton/>
  </>;
};
