import { createContext, useReducer } from 'react';

export enum ConfigAction {
  language,
  theme,
  loggedIn,
  userId,
  tokens,
  imageFile,
}

export const ConfigLanguage = {
  english: 'en',
  spanish: 'es',
};

export const ConfigContext = createContext<any>({
});

export function ConfigProvider(props: any) {
  const defaultState = {
    materialDesign: true,
    language: ConfigLanguage.spanish,
    loggedIn: false,
    userId: undefined,
  };
  const localStorageConfig = localStorage.getItem('config');
  let initialState;
  if (!localStorageConfig) {
    initialState = defaultState;
    localStorage.setItem('config', JSON.stringify(initialState));
  } else {
    initialState = JSON.parse(localStorageConfig);
  }
  const reducer = (state: any, action: any) => {
    // console.log(action); // todo: find out why it repeats twice on login
    let newState;
    switch (action.type) {
    case ConfigAction.language:
      newState = {
        ...state,
        language: action.value,
      };
      break;
    case ConfigAction.theme:
      newState = {
        ...state,
        materialDesign: !state.materialDesign,
      };
      break;
    case ConfigAction.loggedIn:
      newState = {
        ...state,
        loggedIn: action.value,
      };
      break;
    case ConfigAction.userId:
      newState = {
        ...state,
        userId: action.value,
      };
      break;
    case ConfigAction.tokens:
      newState = {
        ...state,
        tokens: action.value,
      };
      break;
    case ConfigAction.imageFile:
      newState = {
        ...state,
        imageFile: action.value,
      };
      break;
    }
    localStorage.setItem('config', JSON.stringify(newState));
    return newState;
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <ConfigContext.Provider value={{
    state,
    dispatch,
  }}>{props.children}</ConfigContext.Provider>;
}
