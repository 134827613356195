import { gql } from '@apollo/client';

export const queryPhotos = gql`
  query($id: ID) {
    photos(id: $id) {
      id
      file
      created
      socialUser {
        id
        user {
          name
        }
      }
    }
  }
`;

export const mutationCreatePhoto = gql`
  mutation ($file: Upload!) {
    createPhoto(file: $file) {
      id
    }
  }
`;

export const mutationDeletePhoto = gql`
  mutation ($id: ID!) {
    deletePhoto(id: $id)
  }
`;
