import {useMutation, useQuery} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {queryExpensesDataGroupList} from 'shared/queries/expenses/expensesDataGroupCrudQueries';
import {
  mutationExpensesPropertyCreate,
  mutationExpensesPropertyDelete,
  queryExpensesPropertyFind,
} from 'shared/queries/expenses/expensesPropertyCrudQueries';
import {onError} from '../../../utils';
import {BackButton} from '../../common/components/BackButton';
import {CenterLoading} from '../../common/components/CenterLoading';
import {ErrorScreen} from '../../common/components/ErrorScreen';
import {GenericSelect} from '../../common/components/GenericSelect';
import {WButton} from '../../common/components/WButton';

export const ExpensesPropertyPage = () => {

  const navigate = useNavigate();
  const params = useParams();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm();
  const {t} = useTranslation();

  const watchFloor = watch('floor');
  const [apartmentOptions, setApartmentOptions] = useState<{ value: string, text: string }[]>([]);
  const [hasPrincipal, setHasPrincipal] = useState<boolean>();

  const {loading, data, error} = useQuery(queryExpensesPropertyFind, {
    skip: !params.id,
    variables: {id: params.id},
  });
  const {
    loading: loadingDataGroupList,
    data: dataDataGroupList,
    error: errorDataGroupList,
  } = useQuery(queryExpensesDataGroupList);

  const [mutateFunction, {loading: loadingCreate}] = useMutation(mutationExpensesPropertyCreate);
  const [mutateFunctionDelete, {loading: loadingDelete}] = useMutation(mutationExpensesPropertyDelete);

  // const floors = Array.from(
  //   {length: 30}, (_, i) => i + 1
  // ).map(value => ({value: value.toString(), text: value.toString()}));
  const floors: { value: string, text: string }[] = [];
  for (let i = 2; i <= 30; i++) {
    floors.push({
      value: i.toString(),
      text: i.toString(),
    });
  }
  floors.unshift(...[
    {
      value: 'Cochera PB',
      text: 'Cochera PB',
    },
    {
      value: 'Cochera P1',
      text: 'Cochera P1',
    },
  ]);
  const apartments = [
    {
      value: 'A',
      text: 'A',
    },
    {
      value: 'B',
      text: 'B',
    },
    {
      value: 'C',
      text: 'C',
    },
    {
      value: 'D',
      text: 'D',
    },
    {
      value: 'E',
      text: 'E',
    },
  ];
  const garages: { value: string, text: string }[] = [];
  for (let i = 1; i <= 22; i++) {
    garages.push({
      value: i.toString(),
      text: i.toString(),
    });
  }

  let property;
  if (params.id) {
    property = data?.expensesPropertyFind;
  } else {
    property = {};
  }

  useEffect(() => {
    const floor = watchFloor ?? property?.floor;
    if (floor === 'Cochera PB' || floor == 'Cochera P1') {
      setApartmentOptions(garages);
    } else {
      setApartmentOptions(apartments);
    }
  }, [
    watchFloor,
    data,
  ]);

  useEffect(() => {
    setValue('apartment', property?.apartment);
  }, [apartmentOptions]);

  if (loading || loadingDataGroupList) {
    return <CenterLoading/>;
  }
  if (error || errorDataGroupList) {
    return <ErrorScreen/>;
  }

  const onSubmit = async (data: any) => {
    await mutateFunction({
      variables: {
        input: {
          ...{id: params.id},
          ...data,
        },
      },
      onCompleted: () => {
        navigate(-1);
      },
      onError: onError,
    });
  };

  const remove = () => {
    mutateFunctionDelete({
      variables: {id: params.id},
      onCompleted: () => {
        toast.success('Eliminado');
        navigate(-1);
      },
    });
  };

  const isLoading = () => {
    return loadingCreate || loadingDelete;
  };

  const dataGroupsOptions = () => {
    return dataDataGroupList.expensesDataGroupList.map(e => {
      return {
        value: e.id,
        text: e.name,
      };
    });
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <GenericSelect register={register}
        name={'floor'}
        value={property.floor}
        options={floors}
        translation={'modules.consortium.floor'}
        required
      />
      <GenericSelect register={register}
        name={'apartment'}
        value={property.apartment}
        options={apartmentOptions}
        translation={'modules.consortium.apartment'}
        required
      />
      <GenericSelect register={register}
        name={'dataGroupId'}
        value={property.dataGroup?.id}
        options={dataGroupsOptions()}
        translation={'modules.expenses.dataGroup'}
      />
      <WButton
        text={t('Save')}
        loading={isLoading()}
        type={'submit'}
      />
      {params.id && <WButton
        text={t('Delete')}
        loading={isLoading()}
        onClick={remove}
      />}
      <BackButton/>
    </form>
  </>;
};
