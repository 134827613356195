import {useQuery} from '@apollo/client';
import {ReactElement, useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {queryUserCellphone} from 'shared/queries/auth/queries';
import {onError} from '../../../utils';
import {CenterLoading} from '../../common/components/CenterLoading';
import {ErrorScreen} from '../../common/components/ErrorScreen';
import {UserPhoneScreen} from '../../common/components/UserPhoneScreen';

export const SetCellphoneWrapper = (): ReactElement => {
  const [enabled, setEnabled] = useState<boolean>();
  const [didQuery, setDidQuery] = useState(false);
  const location = useLocation();

  const {
    loading,
    data,
    error,
  } = useQuery(queryUserCellphone, {
    onError: onError,
    onCompleted: () => setDidQuery(true),
  });

  const user = data?.user;

  useEffect(() => {
    const inUserPage = location.pathname === '/user';
    const inChangePasswordPage = location.pathname === '/auth/changePassword';
    setEnabled(inUserPage || inChangePasswordPage || user?.phone);
  }, [
    location,
    user,
  ]);

  if (!didQuery) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  return enabled ? <Outlet/> : <UserPhoneScreen/>;
};
