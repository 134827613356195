import { MessageEntity } from 'shared/entity/chat/MessageEntity';

type Props = {
  message: MessageEntity,
  right: boolean,
}

const styles = {
  message: {
    backgroundColor: 'white',
    padding: 10,
    marginBottom: 10,
  },
  user: {
    // backgroundColor: 'white',
    // padding: 10,
    // marginBottom: 10,
  },
  textAlignRight: {
    textAlign: 'right',
  },
};

export const MessageComponent = (props: Props) => {
  // todo: get user name from apollo cache
  return <>
    <div style={{
      ...styles.user, ...props.right && styles.textAlignRight as React.CSSProperties,
    }}>{props.message.user.name} - {new Date(props.message.created).toLocaleString()}</div>
    <div style={{
      ...styles.message, ...props.right && styles.textAlignRight as React.CSSProperties,
    }}>{props.message.text}</div>
  </>;
};
