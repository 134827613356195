import { gql } from '@apollo/client';

export const queryTextPosts = gql`
  query {
    textPosts {
      id
      text
      file
      created
      likes {
        id
        socialUser {
          id
          user {
            name
          }
        }
      }
      socialUser {
        id
        user {
          id
          name
        }
      }
    }
  }
`;

export const queryFollowingTextPosts = gql`
  query {
    followingTextPosts {
      id
      text
      file
      created
      likes {
        id
        socialUser {
          id
          user {
            name
          }
        }
      }
      socialUser {
        id
        user {
          id
          name
        }
      }
    }
  }
`;

export const queryTextPost = gql`
  query ($id: ID!) {
    textPost(id: $id) {
      id
      text
      file
      socialUser {
        id
      }
    }
  }
`;

export const querySearchUsers = gql`
  query ($name: String!) {
    searchUsers(name: $name) {
      id
      user {
        id
        name
      }
      followed
    }
  }
`;

export const mutationFollow = gql`
  mutation ($id: ID!) {
    follow(id: $id)
  }
`;

export const mutationUnfollow = gql`
  mutation ($id: ID!) {
    unfollow(id: $id)
  }
`;

export const querySocialFollowersCounters = gql`
  query {
    socialFollowersCounters {
      followers
      following
    }
  }
`;

export const querySocialFollowing = gql`
  query {
    socialFollowing {
      id
      user {
        name
      }
      followed
    }
  }
`;

export const querySocialFollowers = gql`
  query {
    socialFollowers {
      id
      user {
        name
      }
      followed
    }
  }
`;

export const mutationLike = gql`
  mutation ($id: ID!) {
    like(id: $id)
  }
`;

export const mutationUnlike = gql`
  mutation ($id: ID!) {
    unlike(id: $id)
  }
`;
