import React from 'react';
import {Route} from 'react-router-dom';
import {LogmateIndexPage} from './LogmateIndexPage';

const prefix: string = 'logmate';

export const LogmateRoutes = <>
  <Route path={`${prefix}`}
    element={<LogmateIndexPage/>}/>
</>;
