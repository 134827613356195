import { gql } from '@apollo/client';

export const mutationCreateProfile = gql`
  mutation ($input: CreateProfileInput!) {
    createProfile(input: $input) {
      id
      birthdate
      gender
      description
      latitude
      longitude
    }
  }
`;

export const mutationUpdateProfile = gql`
  mutation ($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      birthdate
      gender
      description
    }
  }
`;

export const mutationDeleteProfile = gql`
  mutation ($id: ID!) {
    deleteProfile(id: $id)
  }
`;
