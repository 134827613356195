import { FormControl, InputLabel, NativeSelect, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Gender } from '../../dating/pages/DatingSettingPage';

export const GenderSelect = (props: any) => {

  const {t} = useTranslation();

  return <FormControl fullWidth
    className={'w-100 margin-1'}
  >
    <InputLabel
      variant='outlined'
      htmlFor='gender'
    >
      {t('Gender')}
    </InputLabel>
    <NativeSelect
      input={<OutlinedInput label={t('Gender')}/>}
      inputProps={{
        id: 'gender',
      }}
      {...props.register('gender')}
      defaultValue={props.gender}
    >
      <option></option>
      <option value={Gender.MALE}>{Gender.MALE}</option>
      <option value={Gender.FEMALE}>{Gender.FEMALE}</option>
      <option value={Gender.OTHER}>{Gender.OTHER}</option>
    </NativeSelect>
  </FormControl>;
};
