import {useMutation, useQuery} from '@apollo/client';
import {Delete} from '@mui/icons-material';
import {Checkbox, FormControlLabel, FormGroup, IconButton, TextField} from '@mui/material';
import React, {useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {ConsortiumPollOptionInterface} from 'shared/entity/consortium/ConsortiumPollOptionEntity';
import {
  mutationConsortiumPollCreate,
  mutationConsortiumPollDelete,
  queryConsortiumPoll,
} from 'shared/queries/consortium/queriesPoll';
import {v4 as uuid} from 'uuid';
import {onError} from '../../../utils';
import {BackButton} from '../../common/components/BackButton';
import {CenterLoading} from '../../common/components/CenterLoading';
import {ErrorScreen} from '../../common/components/ErrorScreen';
import {WButton} from '../../common/components/WButton';
import {ConsortiumPoll} from '../components/ConsortiumPoll';
import {ConsortiumPollOptions} from '../components/ConsortiumPollOptions';

export const ConsortiumPollPage = () => {

  const {t} = useTranslation();
  const {register, handleSubmit} = useForm();
  const params = useParams();
  const navigate = useNavigate();

  const defaultOptions: ConsortiumPollOptionInterface[] = [
    {
      id: uuid(),
      order: 0,
      title: '',
      description: '',
    },
    {
      id: uuid(),
      order: 0,
      title: '',
      description: '',
    },
  ];
  const [options, setOptions] = useState<ConsortiumPollOptionInterface[]>(defaultOptions);

  const userId = useMemo(() => localStorage.getItem('userId'), []);

  const {loading, data, error} = useQuery(queryConsortiumPoll, {
    skip: !params.id,
    variables: {id: params.id},
    onCompleted: () => {
      setOptions(JSON.parse(JSON.stringify(entity.options)));
    },
  });
  const [mutateFunction] = useMutation(mutationConsortiumPollCreate);
  const [mutateFunctionDelete] = useMutation(mutationConsortiumPollDelete);

  if (loading) {
    return <CenterLoading/>;
  }
  if (error) {
    return <ErrorScreen/>;
  }

  let entity;
  if (params.id) {
    entity = data.consortiumPollFind;
  } else {
    entity = {};
  }

  const addOptionsToData = (data) => {
    const curatedOptions = options.map(({__typename, ...option}) => option);
    const numberedOptions = curatedOptions.map(
      (option, index) => {
        return {
          ...option,
          order: index,
        };
      },
    );
    return {
      title: data.title,
      description: data.description,
      multipleResponses: data.multipleResponses,
      anonymous: data.anonymous,
      realtime: data.realtime,
      options: numberedOptions,
    };
  };

  const onSubmit = async (data: any) => {
    if (options.length < 2) {
      toast('Agregue al menos 2 opciones');
      return;
    }
    data = addOptionsToData(data);
    await mutateFunction({
      variables: {
        input: {
          ...{id: params.id},
          ...data,
          realtime: true,
        },
      },
      onCompleted: () => {
        navigate(-1);
      },
      onError: onError,
    });
  };

  const remove = () => {
    const confirm: boolean = window.confirm(t('common.confirm') ?? undefined);
    if (!confirm) {
      return;
    }
    mutateFunctionDelete({
      variables: {id: params.id},
      onCompleted: () => {
        toast.success('Eliminado');
        navigate(-1);
      },
    });
  };

  const belongs = () => {
    return entity.user.id === userId;
  };

  const addOption = () => {
    setOptions([
      ...options,
      {
        id: uuid(),
        title: '',
        description: '',
        order: 0,
      },
    ]);
  };

  const deleteOption = (id: string) => {
    const newOptions = options.filter(option => option.id != id);
    setOptions(newOptions);
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      {params.id && !belongs() ? <ConsortiumPoll entity={entity}/>
        : <>
          <TextField
            label={t('common.title')}
            variant='outlined'
            className={'w-100 margin-1'}
            {...register('title')}
            defaultValue={entity.title}
            required
          />
          <TextField
            label={t('common.description')}
            variant='outlined'
            className={'w-100 margin-1'}
            {...register('description')}
            defaultValue={entity.description}
            multiline
            rows={10}
            required
          />
          <FormGroup className={'margin-1'}>
            <FormControlLabel
              control={<Checkbox defaultChecked={entity.anonymous}/>}
              label={t('modules.consortium.groups.polls.anonymous')}
              {...register('anonymous')}
            />
            <FormControlLabel
              control={<Checkbox defaultChecked={entity.multipleResponses}/>}
              label={t('modules.consortium.groups.polls.multipleResponses')}
              {...register('multipleResponses')}
            />
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox defaultChecked={entity.realtime}/>}*/}
            {/*  label={t('modules.consortium.groups.polls.realtime')}*/}
            {/*  {...register('realtime')}*/}
            {/*/>*/}
          </FormGroup>
        </>}
      {options.map((option) => <div key={option.id}
        className={'input-button-container'}>
        <TextField
          label={t('modules.consortium.option')}
          variant='outlined'
          className={'w-100 margin-1'}
          {...register(`option-${option.id}`)}
          onChange={value => option.title = value.target.value}
          defaultValue={option.title}
          required
        />
        <IconButton className={'margin-1 h-100 align-self-center'}
          onClick={() => deleteOption(option.id)}>
          <Delete/>
        </IconButton>
      </div>)}
      <WButton
        text={t('modules.consortium.addOption')}
        onClick={addOption}
      />
      {(!params.id || belongs()) && <WButton
        text={t('Save')}
        // disabled={isDisabled()}
        // isLoading={isLoading()}
        type={'submit'}
      />}
      {params.id && <hr/>}
      {params.id && <ConsortiumPollOptions poll={entity}/>}
      {params.id && belongs() && <WButton
        text={t('Delete')}
        // disabled={isDisabled()}
        // isLoading={isLoading()}
        onClick={remove}
      />}
      <BackButton/>
    </form>
  </>;
};
