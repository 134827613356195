import jwt_decode from 'jwt-decode';
import {ReactElement, useContext, useEffect} from 'react';
import {NavigateFunction, Outlet, useNavigate} from 'react-router-dom';
import {ConfigContext} from '../../../Config';
import {config} from '../../../config';
import {logout} from '../utils/logout';
import {url} from '../../../url';
import {requestMethods, setTokens} from '../../../utils';

const isAuthenticated = (): boolean => {
  return !!localStorage.getItem(config.tokenLocalStorageVariable);
};

export const checkAuth = async (navigate?: NavigateFunction, context?): Promise<void> => {
  if (!isAuthenticated()) {
    if (navigate) {
      navigate(config.loginUrl, {
        replace: true,
      });
    }
    return;
  }
  const token: string | null = localStorage.getItem(config.tokenLocalStorageVariable);
  const refreshToken: string | null = localStorage.getItem(config.refreshTokenLocalStorageVariable);
  if (!token || !refreshToken) {
    if (navigate) {
      navigate(config.loginUrl, {
        replace: true,
      });
    }
    return;
  }
  try {
    const tokenExp: number = parseInt((jwt_decode(token) as { exp: string }).exp);
    const refreshTokenExp: number = parseInt((jwt_decode(refreshToken) as { exp: string }).exp);
    const tokenIsExpired: boolean = Date.now() >= tokenExp * 1000 - 10 * 1000;
    const refreshTokenIsExpired: boolean = Date.now() >= refreshTokenExp * 1000 - 10 * 1000;
    if (refreshTokenIsExpired) {
      await logout(false, navigate, context);
      return;
    }
    if (tokenIsExpired) {
      const responseRefresh: Response = await fetch(`${url.apiUrl}/${url.refresh}`, {
        method: requestMethods.POST,
        headers: {
          authorization: config.bearerPrefix + localStorage.getItem(config.refreshTokenLocalStorageVariable),
        },
      });
      if (responseRefresh.status === 401) {
        await logout(false, navigate, context);
        return;
      }
      await setTokens(responseRefresh, context);
    }
  } catch {
    await logout(false, navigate, context);
  }
};

export const AuthWrapper = (): ReactElement => {

  const navigate: NavigateFunction = useNavigate();
  const context = useContext(ConfigContext);

  useEffect(() => {
    checkAuth(navigate, context).then();
  }, [navigate]);

  return isAuthenticated() ? <Outlet/> : <></>;
};
