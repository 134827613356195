import {ArrowBack, CloseFullscreen, Fullscreen, Home, Language, Palette} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useContext, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {config} from '../../../config';
import {ConfigAction, ConfigContext, ConfigLanguage} from '../../../Config';
import i18n from '../../../i18n';
import {UserImage} from './UserImage';

export default function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(ConfigContext);
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const language = () => {
    let language;
    if (i18n.language === ConfigLanguage.english) {
      language = ConfigLanguage.spanish;
    } else {
      language = ConfigLanguage.english;
    }
    i18n.changeLanguage(language);
    context.dispatch({
      type: ConfigAction.language,
      value: language,
    });
  };

  const theme = () => {
    context.dispatch({type: ConfigAction.theme});
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  const home = () => {
    if (location.pathname != '/') {
      navigate('/');
    }
  };

  const back = () => {
    if (location.pathname != '/') {
      navigate(-1);
    }
  };

  const showHome = () => {
    return location.pathname != '/'
      && location.pathname != '/auth/login'
      && location.pathname != '/auth/social/google'
      && location.pathname != '/auth/social/facebook';
  };

  const showBack = () => {
    return location.pathname != '/'
      && location.pathname != '/auth/login'
      && location.pathname != '/auth/social/google'
      && location.pathname != '/auth/social/facebook';
  };

  const showTitle = () => {
    return location.pathname == '/'
      || location.pathname == '/auth/login'
      || location.pathname == '/auth/social/google'
      || location.pathname == '/auth/social/facebook';
  };

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position='fixed'>
        <Toolbar style={{zIndex: 1}}>
          {showTitle() && <Typography variant={'h6'}>{config.appName}</Typography>}
          {config.navbarButtons.back && showBack() && <Button
            color='inherit'
            onClick={back}
          >
            <ArrowBack/>
          </Button>}
          {config.navbarButtons.home && showHome() && <Button
            color='inherit'
            onClick={home}
          >
            <Home/>
          </Button>}
          <Typography variant='h6'
            component='div'
            sx={{flexGrow: 1}}>
          </Typography>
          {config.navbarButtons.fullscreen && <Button
            color='inherit'
            onClick={toggleFullscreen}
          >
            {!fullscreen ? <Fullscreen/> : <CloseFullscreen/>}
          </Button>}
          {config.navbarButtons.theme && <Tooltip title={'Material design'}>
            <Button
              color='inherit'
              onClick={theme}
            >
              <Palette/>
            </Button>
          </Tooltip>}
          {config.navbarButtons.language && <Button
            color='inherit'
            onClick={language}
          >
            <Language/>
          </Button>}
          {config.navbarButtons.user && context.state.userId && <div className={'small-image-container'}>
            <UserImage clickable/>
          </div>}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
