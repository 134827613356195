import {ApolloProvider} from '@apollo/client';
import {StyledEngineProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es'; // needed for locale to work
import {useEffect} from 'react';
// import 'normalize.css';
import {Toaster} from 'react-hot-toast';
import {BrowserRouter} from 'react-router-dom';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import {apolloClient} from './apollo';
import {capacitorSetup} from './CapacitorSetup';
import { config } from './config';
import {ConfigProvider} from './Config';
import './i18n';
import NavBar from './modules/common/components/NavBar';
import './modules/notes/style.scss';
import './modules/social/style.scss';
import {AppRoutes} from './routes';
import './theme/body.scss';
import './theme/fonts.scss';
import './theme/responsive.scss';
import './theme/standarize.scss';
// import './theme/focus-visible.scss';
// import './theme/rounded.scss';
// import './theme/shadows.scss';
import './theme/theme.scss';

capacitorSetup();

const App = () => {
  useEffect(() => {
    document.title = config.appName;
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <StyledEngineProvider injectFirst>{/* This loads the material css first*/}
        <LocalizationProvider dateAdapter={AdapterMoment}
          adapterLocale={'es'}>
          <ConfigProvider>
            <Toaster/>
            <BrowserRouter>
              <NavBar/>
              <div className={'web web-responsive'}>
                {AppRoutes}
              </div>
            </BrowserRouter>
          </ConfigProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
};

export default App;
