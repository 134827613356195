import {Dialog, List, ListItem, ListItemButton} from '@mui/material';
import {ConsortiumUserCardHeader} from './ConsortiumUserCardHeader';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  voters: any;
}

export const ConsortiumVotersList = (props: SimpleDialogProps) => {
  const {onClose, open, voters} = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose}
      open={open}>
      <List sx={{pt: 0}}>
        {voters?.map((voter) => (
          <ListItem disableGutters
            key={voter.id}>
            <ListItemButton>
              <ConsortiumUserCardHeader user={voter}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
