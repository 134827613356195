import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { NoteEntity } from 'shared/entity/notes/NoteEntity';
import { decrypt } from '../../../encryptor';
import { delay } from '../../../utils';
import { Note } from '../components/Note';
import { queryLabel } from '../queries';
import { strings } from '../strings';

export const LabelNotesPage = () => {

  const {t} = useTranslation();
  let params = useParams();

  const [notes, setNotes] = useState<NoteEntity[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const password = localStorage.getItem('password');

  useQuery(queryLabel, {
    variables: {id: params.id},
    fetchPolicy: 'network-only',
    onCompleted: async data => {
      const notes = JSON.parse(JSON.stringify(data.label.notes));
      if (!password) {
        return;
      }
      try {
        for (const note of notes) {
          note.text = await decrypt(password, note.text);
        }
      } catch (e) {
        toast(strings.BAD_ENCRYPTION);
        return;
      }
      await delay();
      setLoading(false);
      setNotes(notes);
    },
  });

  return <>
    {loading ? <>loading</> : <>
      {!notes?.length && t('No notes')}
      <div className={'flex'}>
        {notes ? notes.map((note: NoteEntity) =>
          <Link key={note.id} to={'/note/' + note.id}>
            <Note note={note}/>
          </Link>) : null}
      </div>
    </>}
  </>;
};
