import { gql } from '@apollo/client';

export const queryDatingCandidates = gql`
  query {
    candidates {
      id
      name
      socialUser {
        socialUserProfile {
          description
        }
      }
    }
  }
`;

export const mutationMatch = gql`
  mutation ($id: ID!) {
    match(id: $id)
  }
`;
