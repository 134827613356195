import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { onError } from '../../../utils';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';
import { mutationCreateLabel, mutationDeleteLabel, mutationUpdateLabel, queryLabel, queryLabels } from '../queries';

export const LabelPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();
  const [loading, setLoading] = useState<boolean>();

  const hasParams = useMemo(() => {
    return !!Object.keys(params).length;
  }, [params]);

  const {
    loading: loadingLabel,
    data: dataLabel,
    error
  } = useQuery(queryLabel, {
    variables: {
      id: params.id,
    },
    skip: !hasParams,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // For some reason form won't update value if not reseted.
      reset();
    },
    onError: onError,
  });

  const [mutateFunctionCreate] = useMutation(mutationCreateLabel, {
    refetchQueries: [
      {
        query: queryLabels,
      },
    ],
  });
  const [mutateFunctionUpdate] = useMutation(mutationUpdateLabel);
  const [mutateFunctionDelete] = useMutation(mutationDeleteLabel, {
    refetchQueries: [
      {
        query: queryLabels,
      },
    ],
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (hasParams) {
        await mutateFunctionUpdate({
          variables: {
            input: {
              ...data,
              id: params.id,
            },
          },
        });
        navigate(-1);
      } else {
        await mutateFunctionCreate({
          variables: {
            input: data,
          },
        });
        navigate(-1);
      }
    } catch {
      setLoading(false);
    }
    setLoading(false);
  };

  const remove = () => {
    mutateFunctionDelete({
      variables: {
        id: params.id,
      },
    }).then(() => {
      navigate(-1);
    });
  };

  const isLoading = () => {
    return loadingLabel || loading;
  };

  const isDisabled = () => {
    return loadingLabel || loading || !!error;
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        {...register('name')}
        className={`${isLoading() && 'loading'}`}
        disabled={isDisabled()}
        defaultValue={dataLabel?.label.name}
      />
      <WButton
        text={!hasParams ? t('Create') : t('Save')}
        type='submit'
        disabled={isDisabled()}
        loading={isLoading()}
      />
    </form>
    {hasParams &&
      <WButton
        text={t('Delete')}
        onClick={remove}
        disabled={isDisabled()}
        loading={isLoading()}
      />
    }
    <BackButton/>
  </>;
};
