import React from 'react';
import { Route } from 'react-router-dom';
import { ChatIndexPage } from './pages/ChatIndexPage';
import { ChatUsersPage } from './pages/ChatUsersPage';
import { ConversationPage } from './pages/ConversationPage';

const prefix: string = 'chat';

export const ChatRoutes: JSX.Element = <>
  <Route path={`${prefix}`} element={<ChatIndexPage/>}/>
  <Route path={`${prefix}/users`} element={<ChatUsersPage/>}/>
  <Route path={`${prefix}/:id`} element={<ConversationPage/>}/>
</>;
