import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {ConfigContext} from './Config';
import { config } from './config';
import { logout } from './modules/auth/utils/logout';
import { WButton } from './modules/common/components/WButton';

export const IndexPage = () => {

  const navigate = useNavigate();
  const {t} = useTranslation();
  const context = useContext(ConfigContext);

  return <>
    {config.indexMenus.profile && <WButton
      text={t('index.profile.title')}
      link={'user'}
      // label={t('index.profile.description')}
    />}
    {config.indexMenus.notes && <WButton
      text={t('index.notes.title')}
      link={'notes'}
      // label={t('index.notes.description')}
    />}
    {config.indexMenus.social && <WButton
      text={t('index.social.title')}
      link={'social'}
      // label={t('index.social.description')}
    />}
    {config.indexMenus.dating && <WButton
      text={t('index.dating.title')}
      link={'dating'}
      // label={t('index.dating.description')}
    />}
    {config.indexMenus.chat && <WButton
      text={t('index.chat.title')}
      link={'chat'}
      // label={t('index.chat.description')}
    />}
    {config.indexMenus.notifications && <WButton
      text={t('index.notifications.title')}
      link={'notifications'}
      label={t('index.notifications.description')}
    />}
    {config.indexMenus.consortium && <WButton
      text={t('index.consortium.title')}
      link={'consortium'}
      label={t('index.consortium.description')}
    />}
    {config.indexMenus.expenses && <WButton
      text={t('index.expenses.title')}
      link={'expenses'}
      label={t('index.expenses.description')}
    />}
    {config.indexMenus.logmate && <WButton
      text={t('index.logmate.title')}
      link={'logmate'}
      label={t('index.logmate.description')}
    />}
    <WButton
      text={t('Logout')}
      onClick={() => logout(true, navigate, context)}
      extra={{
        color: 'error',
        'data-testid': 'logout',
      }}
    />
  </>;
};
