import React from 'react';
import { Route } from 'react-router-dom';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { LoginPage } from './pages/LoginPage';
import { RecoverPasswordPage } from './pages/RecoverPasswordPage';
import { RegisterPage } from './pages/RegisterPage';
import { SocialPage } from './pages/SocialPage';

export const AuthRoutes = <>
  <Route path='auth/login'
    element={<LoginPage/>}/>
  <Route path='auth/register'
    element={<RegisterPage/>}/>
  <Route path='auth/forgotPassword'
    element={<ForgotPasswordPage/>}/>
  <Route path='auth/recoverPassword/:token'
    element={<RecoverPasswordPage/>}/>
  <Route path='auth/social/:service'
    element={<SocialPage/>}/>
</>;
