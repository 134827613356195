export enum Apps {
  DEVELOPER,
  ENCONSORCIO,
}

const developerConfig = {
  app: Apps.DEVELOPER,
  appName: 'App name',
  loginUrl: '/auth/login',
  tokenLocalStorageVariable: 'token',
  refreshTokenLocalStorageVariable: 'refreshToken',
  passwordLocalStorageVariable: 'password', // todo: move this to the notes module
  configLocalStorageVariable: 'config',
  bearerPrefix: 'bearer ',
  userIdLocalStorageVariable: 'userId',
  indexMenus: {
    profile: false,
    social: true,
    notes: true,
    dating: false,
    chat: true,
    notifications: true,
    consortium: true,
    expenses: true,
    logmate: true,
  },
  navbarButtons: {
    home: true,
    fullscreen: true,
    theme: true,
    language: true,
    user: true,
    back: true,
  },
  login: {
    guest: true,
    google: true,
    facebook: true,
    email: true,
  },
  modules: {
    profile: {
      sessions: true,
      validate: true,
      delete: true,
      picture: true,
      email: true,
      phone: true,
    },
  },
};

const consortiumConfig = {
  app: Apps.ENCONSORCIO,
  appName: 'EnConsorcio',
  loginUrl: '/auth/login',
  tokenLocalStorageVariable: 'token',
  refreshTokenLocalStorageVariable: 'refreshToken',
  passwordLocalStorageVariable: 'password', // todo: move this to the notes module
  configLocalStorageVariable: 'config',
  bearerPrefix: 'bearer ',
  userIdLocalStorageVariable: 'userId',
  indexMenus: {
    profile: false,
    social: false,
    notes: false,
    dating: false,
    chat: false,
    notifications: false,
    consortium: true,
    expenses: false,
    logmate: false,
  },
  navbarButtons: {
    home: true,
    fullscreen: false,
    theme: false,
    language: false,
    user: true,
    back: true,
  },
  login: {
    guest: false,
    google: true,
    facebook: false,
    email: true,
  },
  modules: {
    profile: {
      sessions: false,
      validate: true,
      delete: false,
      picture: true,
      email: true,
      phone: true,
    },
  },
};

export const config = consortiumConfig;
