import {useTranslation} from 'react-i18next';
import {WButton} from '../../common/components/WButton';

export const ExpensesLoadTransfersPage = () => {

  const {t} = useTranslation();

  const loadTransfers = () => {

  };

  return <WButton
    text={t('modules.expenses.loadTransfers')}
    onClick={loadTransfers}
  />;
};
