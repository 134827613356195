import {useMutation, useQuery} from '@apollo/client';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {
  mutationConsortiumPropertyCreate,
  mutationConsortiumPropertyDelete,
  queryConsortiumProperties,
  queryConsortiumProperty,
} from 'shared/queries/consortium/queries';
import {onError} from '../../../utils';
import {BackButton} from '../../common/components/BackButton';
import {CenterLoading} from '../../common/components/CenterLoading';
import {GenericSelect} from '../../common/components/GenericSelect';
import {WButton} from '../../common/components/WButton';

// todo: use imported type
enum ConsortiumPropertyType {
  TENANT = 'TENANT',
  OWNER = 'OWNER',
}

export const ConsortiumPropertyPage = () => {

  const navigate = useNavigate();
  const params = useParams();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm();
  const {t} = useTranslation();

  const watchFloor = watch('floor');
  const [apartmentOptions, setApartmentOptions] = useState<{value: string, text: string}[]>([]);
  const [hasPrincipal, setHasPrincipal] = useState<boolean>();

  const {loading, data: data, error} = useQuery(queryConsortiumProperty, {
    skip: !params.id,
    variables: {input: {id: params.id}},
  });
  const {loading: loadingProperties, data: dataProperties, error: errorProperties} = useQuery(queryConsortiumProperties);
  const [mutateFunction, {loading: loadingCreate}] = useMutation(mutationConsortiumPropertyCreate);
  const [mutateFunctionDelete, {loading: loadingDelete}] = useMutation(mutationConsortiumPropertyDelete);

  // const floors = Array.from(
  //   {length: 30}, (_, i) => i + 1
  // ).map(value => ({value: value.toString(), text: value.toString()}));
  const floors: {value: string, text: string}[] = [];
  for (let i = 2; i <= 30; i++) {
    floors.push({
      value: i.toString(),
      text: i.toString(),
    })
  }
  floors.unshift(...[
    {
      value: 'Cochera PB',
      text: 'Cochera PB',
    },
    {
      value: 'Cochera P1',
      text: 'Cochera P1',
    },
  ])
  const apartments = [
    {
      value: 'A',
      text: 'A',
    },
    {
      value: 'B',
      text: 'B',
    },
    {
      value: 'C',
      text: 'C',
    },
    {
      value: 'D',
      text: 'D',
    },
    {
      value: 'E',
      text: 'E',
    },
  ];
  const garages: {value: string, text: string}[] = [];
  for (let i = 1; i <= 22; i++) {
    garages.push({
      value: i.toString(),
      text: i.toString(),
    })
  }
  const types = [
    {value: ConsortiumPropertyType.OWNER, text: t('modules.consortium.owner')},
    {value: ConsortiumPropertyType.TENANT, text: t('modules.consortium.tenant')},
  ];

  let property;
  if (params.id) {
    property = data?.consortiumPropertyFind;
  } else {
    property = {};
  }

  useEffect(() => {
    const floor = watchFloor ?? property?.floor;
    if (floor === 'Cochera PB' || floor == 'Cochera P1') {
      setApartmentOptions(garages);
    } else {
      setApartmentOptions(apartments);
    }
  }, [watchFloor, data]);

  useEffect(() => {
    setValue('apartment', property?.apartment);
  }, [apartmentOptions]);

  // useEffect(() => {
  //   if (!dataProperties) {
  //     return;
  //   }
  //   const principal = dataProperties?.consortiumPropertyList.filter(property => property.principal);
  //   // setHasPrincipal(!!principal.length);
  //   if (!params.id) {
  //     setValue('principal',  true);
  //   }
  // }, [dataProperties]);

  if (loading) {
    return <CenterLoading/>;
  }
  if (error) {
    return <>Error</>;
  }

  const onSubmit = async (data: any) => {
    await mutateFunction({
      variables: {
        input: {
          ...{id: params.id},
          ...data,
        },
      },
      onCompleted: () => {
        navigate(-1);
      },
      onError: onError,
    });
  };

  const remove = () => {
    mutateFunctionDelete({
      variables: {id: params.id},
      onCompleted: () => {
        toast.success('Eliminado');
        navigate(-1);
      },
    });
  };

  const isLoading = () => {
    return loadingCreate || loadingDelete;
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <GenericSelect register={register}
        name={'floor'}
        value={property.floor}
        options={floors}
        translation={'modules.consortium.floor'}
        required
      />
      <GenericSelect register={register}
        name={'apartment'}
        value={property.apartment}
        options={apartmentOptions}
        translation={'modules.consortium.apartment'}
        required
      />
      <GenericSelect register={register}
        name={'type'}
        value={property.type}
        options={types}
        translation={'modules.consortium.type'}
        required
      />
      <FormGroup className={'margin-1'}>
        <FormControlLabel
          control={<Checkbox defaultChecked={property.principal ?? true}/>}
          label={t('modules.consortium.groups.properties.principal')}
          {...register('principal')}
        />
      </FormGroup>
      <WButton
        text={t('Save')}
        loading={isLoading()}
        type={'submit'}
      />
      {params.id && <WButton
        text={t('Delete')}
        loading={isLoading()}
        onClick={remove}
      />}
      <BackButton/>
    </form>
  </>;
};
