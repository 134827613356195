export const url = {
  apiUrl: process.env.REACT_APP_API_URL,
  login: 'login',
  register: 'register',
  refresh: 'refresh',
  status: 'status',
  apiPrefix: '/api',
  forgotPassword: 'lost',
  recoverPassword: 'recover',
  logout: 'logout',
  upload: 'upload',
  file: 'file',
  graphql: 'graphql',
  guest: 'guest',
  checkauth: 'checkauth',
  saveSubscription: 'saveSubscription',
  fileUrl: 'fileUrl',
};
