import { gql } from '@apollo/client';

export const queryUser = gql`
  query {
    user {
      id
      name
      email
      phone
      imageFile
      validated
    }
  }
`;

export const queryUserEnabled = gql`
  query {
    user {
      id
      enabled
    }
  }
`;

export const queryUserCellphone = gql`
  query {
    user {
      id
      phone
    }
  }
`;

export const queryUserImageFile = gql`
  query {
    user {
      id
      imageFile
    }
  }
`;

export const queryOtherUserImageFile = gql`
  query($id: ID!) {
    otherUser(id: $id) {
      id
      imageFile
    }
  }
`;

export const querySessions = gql`
  query {
    sessions {
      id
      ip
      created
    }
  }
`;

export const mutationUpdateUser = gql`
  mutation ($input: UpdateUserInput!,  $file: Upload) {
    updateUser(input: $input,  file: $file) {
      name
      email
      imageFile
    }
  }
`;

export const mutationChangePassword = gql`
  mutation ($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

export const mutationSendValidationEmail = gql`
  mutation {
    sendValidationEmail {
      code
    }
  }
`;

export const mutationValidateEmail = gql`
  mutation ($input: ValidateEmailInput!) {
    validateEmail(input: $input) {
      code
    }
  }
`;

export const mutationDeleteSession = gql`
  mutation ($id: ID!) {
    deleteSession(id: $id)
  }
`;

export const mutationDeleteUser = gql`
  mutation {
    deleteUser
  }
`;

export const queryHasPassword = gql`
  query {
    hasPassword
  }
`;
