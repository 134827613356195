import { useMutation, useQuery } from '@apollo/client';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mutationChangePassword, queryHasPassword } from 'shared/queries/auth/queries';
import { onError } from '../../../utils';
import { WButton } from '../../common/components/WButton';

export const ChangePasswordPage = () => {
  const {
    register,
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>();

  const {data: dataHasPassword} = useQuery(queryHasPassword);
  const [mutateFunctionUpdate] = useMutation(mutationChangePassword);

  const onSubmit = async (data: any) => {
    setLoading(true);
    await mutateFunctionUpdate({
      variables: {
        input: {
          ...data,
        },
      },
      onError,
      onCompleted: () => {
        toast.success('');
        navigate(-1);
      },
    });
    setLoading(false);
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={!dataHasPassword?.hasPassword ? t('Old password (does not have)') : t('Old password')}
        variant='outlined'
        className={'w-100 margin-1'}
        required
        type={!dataHasPassword?.hasPassword ? 'text' : 'password'}
        {...register('oldPassword')}
        disabled={!dataHasPassword?.hasPassword}
      />
      <TextField
        label={t('New password')}
        variant='outlined'
        className={'w-100 margin-1'}
        required
        type={'password'}
        {...register('newPassword')}
      />
      <WButton
        text={t('Change password')}
        disabled={loading}
        loading={loading}
        type={'submit'}
      />
    </form>
  </>;
};
