import { gql } from '@apollo/client';

export const mutationConsortiumBudgetCreate = gql`
  mutation($input: ConsortiumBudgetCreateInput!, $file: Upload!) {
    consortiumBudgetCreate(input: $input, file: $file) {
      id
    }
  }
`;

export const mutationConsortiumBudgetDelete = gql`
  mutation($id: ID!) {
    consortiumBudgetDelete(id: $id)
  }
`;
