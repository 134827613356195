import { useQuery } from '@apollo/client/react';
import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialUserEntity } from 'shared/entity/social/SocialUserEntity';
import { querySearchUsers } from '../../social/queries';
import { ChatUserCard } from '../components/ChatlUserCard';

export const ChatUsersPage = () => {

  const {t} = useTranslation();
  const [input, setInput] = useState('');
  const {
    data,
    loading,
  } = useQuery(querySearchUsers, {
    fetchPolicy: 'network-only',
    variables: {
      name: input,
    },
  });

  const onChange = (e: any) => {
    setInput(e.target.value);
  };

  return <>
    <input onChange={onChange}/>
    {loading && <CircularProgress/>}
    {data && !data.searchUsers.length && t('No results')}
    {data && data.searchUsers.map((socialUser: SocialUserEntity) =>
      <ChatUserCard socialUser={socialUser}
        key={socialUser.id}/>,
    )}
  </>;
};
