import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryConsortiumTicketsUser } from 'shared/queries/consortium/queries';
import { BackButton } from '../../common/components/BackButton';
import { WButton } from '../../common/components/WButton';
import { ConsortiumTicket } from '../components/ConsortiumTicket';

export const ConsortiumTicketsUserPage = () => {

  const {t} = useTranslation();

  const {loading, data: data, error} = useQuery(queryConsortiumTicketsUser);

  if (error) {
    return <>Error</>;
  }

  const tickets = data?.consortiumTicketListUser ?? [];

  return <>
    <WButton
      text={t('modules.consortium.addTicket')}
      link={'/consortium/ticket'}
    />
    <BackButton/>
    {tickets.map(ticket => <ConsortiumTicket key={ticket.id} entity={ticket}
      clickable/>)}
  </>;
};
