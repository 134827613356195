export const formatDate = (date: Date | string) => {
  if (!date) {
    return;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const y = date.getFullYear();
  const m = addPad(date.getMonth() + 1);
  const d = addPad(date.getDate());
  const hh = addPad(date.getHours());
  const mm = addPad(date.getMinutes());
  const ss = addPad(date.getSeconds());
  return `${d}/${m}/${y} ${hh}:${mm}`;
};

const addPad = (date: string | number) => {
  if (date.toString().length < 2) return '0' + date;
  return date;
};
