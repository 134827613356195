export const handleSharing = async (text: string) => {
  if (navigator.share) {
    await navigator
      .share({
        text,
      });
  } else {
    alert('This feature is only available on mobile');
  }
};
