import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {ConfigLanguage} from './Config';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      titles: {
        'Log in': 'Log in',
      },
      index: {
        profile: {
          title: 'Profile',
          description: 'Setup your profile:',
        },
        social: {
          title: 'Social',
          description: 'Social app with posts:',
        },
        notes: {
          title: 'Notes',
          description: 'Note taking app with encrypted notes:',
        },
        chat: {
          title: 'Chat',
          description: 'Chat app:',
        },
        notifications: {
          title: 'Notifications',
          description: '',
        },
        consortium: {
          title: 'Consortium',
          description: '',
        },
        expenses: {
          title: 'Expenses',
          description: '',
        },
        logmate: {
          title: 'Logmate',
          description: '',
        },
      },
      'Login': 'Login',
      'Logout': 'Logout',
      'guestNotice': '* Guest accounts expire after 1 day of inactivity unless email and password are created to login again.',
      'keywordNotice': 'First, please set the keyword that encrypts the notes. Write it down because you\'ll need it again to decrypt your notes. This key won\'t be sent to the server.',
      'cancel': 'Cancel',
      modules: {
        auth: {
          validationEmailSent: 'Validation email sent. Check your email.',
          emailVerified: 'Email verified successfully',
          emailAlreadyVerified: 'Email already verified',
          couldNotVerify: 'Could not verify',
          userCreated: 'User created',
          recoverPasswordEmailSent: 'Check your email for login instructions',
          updatedPassword: 'Your password has been updated successfully',
        },
        consortium: {
          property: 'Property',
          properties: 'Properties',
          ticket: 'Ticket',
          tickets: 'Tickets',
          floor: 'Floor',
          apartment: 'Apartment',
          addProperty: 'Add property',
          addTicket: 'Add ticket',
          ticketsUser: 'My tickets',
        },
      },
      common: {
        title: 'Title',
        description: 'Description',
      },
      errors: {
        connectionError: 'Connection error',
        auth: {
          badPassword: 'Bad password',
          existingUser: 'Existing user',
        },
      },
    },
  },
  es: {
    translation: {
      Users: 'Usuarios',
      titles: {
        'Log in': 'Inicio de sesión',
      },
      index: {
        profile: {
          title: 'Perfil',
          description: 'Configura tu perfil:',
        },
        social: {
          title: 'Social',
          description: 'Aplicación social con posts:',
        },
        notes: {
          title: 'Notas',
          description: 'Aplicación para tomar notas encriptadas:',
        },
        chat: {
          title: 'Chat',
          description: 'Chat app:',
        },
        notifications: {
          title: 'Notificaciones',
          description: '',
        },
        consortium: {
          title: 'Consorcio',
          description: '',
        },
        expenses: {
          title: 'Expenses',
          description: '',
        },
        logmate: {
          title: 'Logmate',
          description: '',
        },
      },
      'Continue with Google': 'Continuar con Google',
      'Continue with Facebook': 'Continuar con Facebook',
      'Login': 'Iniciar sesión',
      'Logout': 'Cerrar sesión',
      'guestNotice': '* Las cuentas de invitado expiran luego de 1 día de inactividad salvo que un email y contraseña sean creados para iniciar sesión nuevamente.',
      'Change picture': 'Cambiar imagen',
      'Save': 'Guardar',
      'Change password': 'Cambiar contraseña',
      'Old password (does not have)': 'Contraseña anterior (no tiene)',
      'Old password': 'Contraseña anterior',
      'New password': 'Nueva contraseña',
      'Email': 'Correo electrónico',
      'Password': 'Contraseña',
      'Name': 'Nombre',
      'Register': 'Registrarse',
      'Forgot password?': '¿Olvidaste tu contraseña?',
      'Forgot password': 'Olvido de contraseña',
      'Guest*': 'Invitado*',
      'Recover password': 'Recuperar contraseña',
      'Back': 'Volver',
      'Language': 'Idioma',
      'Posts': 'Posts',
      'My posts': 'Mis posts',
      'Search users': 'Buscar usuarios',
      'Followees': 'Siguiendo',
      'Followers': 'Seguidores',
      'Validate email': 'Validar correo electrónico',
      'Sessions': 'Sesiones',
      'Delete user': 'Eliminar usuario',
      'Follow': 'Seguir',
      'Unfollow': 'Dejar de seguir',
      'Upload picture': 'Subir imagen',
      'Create': 'Crear',
      'Delete': 'Eliminar',
      'Create a post': 'Crear un post',
      'You have no followers': 'No tienes seguidores',
      'You have no followees': 'No sigues a nadie',
      'No results': 'No hay resultados',
      'Keyword:': 'Keyword:',
      'keywordNotice': 'Primero, por favor defina la keyword que encripta las notas. Anótela porque la necesitará nuevamente para desencriptar sus notas. Esta clave no será enviada al servidor.',
      'Create a note': 'Crear nota',
      'Label': 'Etiqueta',
      'Labels': 'Etiquetas',
      'No labels': 'No hay etiquetas',
      'Create a label': 'Crear etiqueta',
      'No notes': 'No hay notas',
      'Notes': 'Notas',
      'cancel': 'Cancelar',
      'Phone': 'Celular',
      modules: {
        auth: {
          validationEmailSent: 'Email de validación enviado. Revisa tus emails.',
          emailVerified: 'Email verificado con éxito',
          emailAlreadyVerified: 'Email ya verificado',
          couldNotVerify: 'No se pudo verificar',
          userCreated: 'Usuario creado',
          recoverPasswordEmailSent: 'Revisa tu email para instrucciones sobre cómo iniciar sesión',
          updatedPassword: 'Has cambiado tu contraseña satisfactoriamente',
        },
        consortium: {
          property: 'Propiedad',
          properties: 'Propiedades',
          ticket: 'Ticket',
          tickets: 'Tickets',
          floor: 'Piso',
          apartment: 'Departamento',
          type: 'Tipo',
          owner: 'Propietario',
          tenant: 'Inquilino',
          addProperty: 'Agregar propiedad',
          addTicket: 'Nuevo ticket',
          ticketsUser: 'Mis tickets',
          attachBudget: 'Adjuntar presupuesto',
          uploadBudget: 'Subir',
          polls: 'Votaciones',
          addPoll: 'Nueva votación',
          addOption: 'Agregar opción',
          option: 'Opción',
          groups: {
            polls: {
              multipleResponses: 'Permitir elegir más de una opción',
              anonymous: 'Votación anónima',
              realtime: 'Mostrar conteo mientras se vota',
            },
            properties: {
              principal: 'Propiedad principal',
            },
          },
          errors: {
            duplicated: 'Propiedad ya existente',
            editPollNotAllowed: 'No se permite editar una votación existente',
          },
        },
        expenses: {
          costGroups: 'Expensas',
          dataGroups: 'Grupos de datos',
          dataGroup: 'Grupo de datos',
          loadTransfers: 'Cargar transferencias',
        },
      },
      common: {
        title: 'Título',
        description: 'Descripción',
        confirm: 'Por favor, confirme la acción.',
        new: 'Nuevo',
        name: 'Nombre',
      },
      errors: {
        connectionError: 'Error de conexión',
        unallowed: 'No permitido',
        auth: {
          badPassword: 'Contraseña inválida',
          existingUser: 'Usuario existente',
        },
      },
    },
  },
};

const localStorageConfig = localStorage.getItem('config');
let language = ConfigLanguage.spanish;
if (localStorageConfig) {
  language = JSON.parse(localStorageConfig).language;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
