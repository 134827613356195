import { ApolloClient, ApolloLink, fromPromise, InMemoryCache, ServerParseError } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { checkAuth } from './modules/auth/wrappers/AuthWrapper';
import { url } from './url';

/*
https://stackoverflow.com/questions/55902881/update-apolloclient-headers-after-it-was-initialised
 */

const link = createUploadLink({
  uri: `${url.apiUrl}/graphql`,
  // credentials: 'include', // for cookies
});

const onErrorLink = onError(error => {
  if ((error?.networkError as ServerParseError)?.statusCode === 401) {
    // https://stackoverflow.com/questions/61327448/how-to-refresh-jwt-token-using-apollo-and-graphql
    return fromPromise(checkAuth()).flatMap(() => {
      return error.forward(error.operation);
    });
  }
});

const headerLink = setContext((request, previousContext) => ({
  headers: {
    ...previousContext.headers,
    authorization: 'bearer ' + localStorage.getItem('token'),
  },
}));

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  link: ApolloLink.from([
    onErrorLink,
    headerLink.concat(link), // it's important that this is the last one
  ]),
});
