import {gql} from '@apollo/client';

export const mutationLogmateLogCreate = gql`
  mutation ($input: CreateLogmateLogInput!) {
    logmateLogCreate(input: $input) {
      id
    }
  }
`;

export const queryLogmateLogList = gql`
  query {
    logmateLogList {
      id
      name
      created
    }
  }
`;
